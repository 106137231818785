import { FunctionComponent_Of_60E46241 } from "../../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { UI_createFieldSimple } from "../../Utils.fs.js";
import { Types_Full_getProbeName } from "../../../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.24.0/String.js";
import { column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { append, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.24.0/List.js";
import { h5 } from "../../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option, div } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { p } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_1, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { IInputType } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { localFormat } from "../../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { toLocalTime } from "../../fable_modules/fable-library-js.4.24.0/Date.js";
import * as react from "react";
import { Inside_ReactList } from "../../GogoFulma.fs.js";
import { saveButton, mediaContents } from "./ReactComponents.fs.js";
import { columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";

export const root = FunctionComponent_Of_60E46241((props) => {
    const mkInput = UI_createFieldSimple;
    const sensor = props.Sensor;
    let fields;
    const makefield = (title, calibration, id, action) => {
        let children_2, children;
        const probeName = (title == null) ? Types_Full_getProbeName(toText(printf("sensor.s%i"))(id), sensor) : title;
        if (calibration == null) {
            return column(empty(), ofArray([h5(empty())(singleton(probeName)), div(singleton(new Option(0, [])), ofArray([p(empty(), singleton(button(ofArray([new Option_1(14, [true]), new Option_1(0, [new Color_IColor(1, [])])]), singleton("VALEUR")))), p(empty(), singleton(mkInput(id, "", new IInputType(7, []), "0.00", empty(), new Fa_IconOption(11, ["fas fa-poll"]), action)))]))]));
        }
        else {
            const calib = calibration;
            const v = (calib.Operation.tag === 0) ? calib.Value : -calib.Value;
            const lastDate = localFormat(french, "dd/MM/yy HH:mm", toLocalTime(calib.Date));
            return column(empty(), ofArray([h5(empty())(singleton(probeName)), div(singleton(new Option(0, [])), ofArray([p(empty(), singleton(button(ofArray([new Option_1(14, [true]), new Option_1(0, [new Color_IColor(1, [])])]), singleton("VALEUR")))), p(empty(), singleton(mkInput(id, "", new IInputType(7, []), v.toString(), empty(), new Fa_IconOption(11, ["fas fa-thermometer-three-quarters"]), action)))])), (children_2 = [(children = [toText(printf("Dernière modification: %s"))(lastDate)], react.createElement("i", {}, ...children))], react.createElement("p", {}, ...children_2))]));
        }
    };
    const list_2 = append(append(Inside_ReactList(makefield(sensor.S1Title, sensor.S1Calibration, 1, (inputText) => {
        props.UpdateCallback([1, inputText]);
    })), Inside_ReactList(makefield(sensor.S2Title, sensor.S2Calibration, 2, (inputText_1) => {
        props.UpdateCallback([2, inputText_1]);
    }))), Inside_ReactList(makefield(sensor.S3Title, sensor.S3Calibration, 3, (inputText_2) => {
        props.UpdateCallback([3, inputText_2]);
    })));
    let list_3;
    const matchValue_1 = sensor.S4Activated;
    let matchResult;
    if (matchValue_1 != null) {
        if (matchValue_1) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            list_3 = append(list_2, Inside_ReactList(makefield(sensor.S4Title, sensor.S4Calibration, 4, (inputText_3) => {
                props.UpdateCallback([4, inputText_3]);
            })));
            break;
        }
        default:
            list_3 = list_2;
    }
    let list_4;
    const matchValue_2 = sensor.S5Activated;
    let matchResult_1;
    if (matchValue_2 != null) {
        if (matchValue_2) {
            matchResult_1 = 0;
        }
        else {
            matchResult_1 = 1;
        }
    }
    else {
        matchResult_1 = 1;
    }
    switch (matchResult_1) {
        case 0: {
            list_4 = append(list_3, Inside_ReactList(makefield(sensor.S5Title, sensor.S5Calibration, 5, (inputText_4) => {
                props.UpdateCallback([5, inputText_4]);
            })));
            break;
        }
        default:
            list_4 = list_3;
    }
    let list_5;
    const matchValue_3 = sensor.S6Activated;
    let matchResult_2;
    if (matchValue_3 != null) {
        if (matchValue_3) {
            matchResult_2 = 0;
        }
        else {
            matchResult_2 = 1;
        }
    }
    else {
        matchResult_2 = 1;
    }
    switch (matchResult_2) {
        case 0: {
            list_5 = append(list_4, Inside_ReactList(makefield(sensor.S6Title, sensor.S6Calibration, 5, (inputText_5) => {
                props.UpdateCallback([6, inputText_5]);
            })));
            break;
        }
        default:
            list_5 = list_4;
    }
    let list_6;
    const matchValue_4 = sensor.S7Activated;
    let matchResult_3;
    if (matchValue_4 != null) {
        if (matchValue_4) {
            matchResult_3 = 0;
        }
        else {
            matchResult_3 = 1;
        }
    }
    else {
        matchResult_3 = 1;
    }
    switch (matchResult_3) {
        case 0: {
            list_6 = append(list_5, Inside_ReactList(makefield(sensor.S7Title, sensor.S7Calibration, 7, (inputText_6) => {
                props.UpdateCallback([7, inputText_6]);
            })));
            break;
        }
        default:
            list_6 = list_5;
    }
    let list_7;
    const matchValue_5 = sensor.S8Activated;
    let matchResult_4;
    if (matchValue_5 != null) {
        if (matchValue_5) {
            matchResult_4 = 0;
        }
        else {
            matchResult_4 = 1;
        }
    }
    else {
        matchResult_4 = 1;
    }
    switch (matchResult_4) {
        case 0: {
            list_7 = append(list_6, Inside_ReactList(makefield(sensor.S8Title, sensor.S8Calibration, 8, (inputText_7) => {
                props.UpdateCallback([8, inputText_7]);
            })));
            break;
        }
        default:
            list_7 = list_6;
    }
    let list_8;
    const matchValue_6 = sensor.S9Activated;
    let matchResult_5;
    if (matchValue_6 != null) {
        if (matchValue_6) {
            matchResult_5 = 0;
        }
        else {
            matchResult_5 = 1;
        }
    }
    else {
        matchResult_5 = 1;
    }
    switch (matchResult_5) {
        case 0: {
            list_8 = append(list_7, Inside_ReactList(makefield(sensor.S9Title, sensor.S9Calibration, 9, (inputText_8) => {
                props.UpdateCallback([9, inputText_8]);
            })));
            break;
        }
        default:
            list_8 = list_7;
    }
    const matchValue_7 = sensor.S10Activated;
    let matchResult_6;
    if (matchValue_7 != null) {
        if (matchValue_7) {
            matchResult_6 = 0;
        }
        else {
            matchResult_6 = 1;
        }
    }
    else {
        matchResult_6 = 1;
    }
    switch (matchResult_6) {
        case 0: {
            fields = append(list_8, Inside_ReactList(makefield(sensor.S10Title, sensor.S10Calibration, 10, (inputText_9) => {
                props.UpdateCallback([10, inputText_9]);
            })));
            break;
        }
        default:
            fields = list_8;
    }
    const description = ofArray([react.createElement("p", {}, "Veuiller indiquer les valeurs de calibration pour les sondes."), react.createElement("p", {}, "Note: les valeurs sont prises en compte dès leur enregistrement et remplacent les précédentes.")]);
    return mediaContents({
        Color: new Color_IColor(2, []),
        Contents: ofArray([columns(empty(), fields), saveButton({
            Action: (_arg) => {
                props.SaveCallback();
            },
            WaitingForSave: props.CanSave,
        })]),
        Description: description,
        Icon: new Fa_IconOption(11, ["fas fa-plus-circle"]),
        Title: "Réglage des calibrations",
    });
}, undefined, undefined, undefined, "root", "/Users/royalefougard/workspace/iotpool-front3/src/Page/SensorComponents/Admin.fs", 13);

