import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, lambda_type, unit_type, tuple_type, int32_type, option_type, class_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { DownloadTimePeriod_$reflection } from "../Types.fs.js";
import { FunctionComponent_Of_60E46241 } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { Color_IColor, Common_GenericOption, Modifier_IModifier } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { concat, append, reverse, skip, take, mapIndexed, map as map_1, empty, singleton, ofArray } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { item, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { month as month_2, year as year_4, now as now_1, op_Subtraction, dayOfWeek, addDays, day, create } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { days } from "../fable_modules/fable-library-js.4.24.0/TimeSpan.js";
import { toList, filter, map, sum } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.24.0/Range.js";
import { Option, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { UI_subButtonBar, Seq_split } from "../Utils.fs.js";
import { mediaContents } from "../Page/SensorComponents/ReactComponents.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.24.0/Seq2.js";
import { numberHash } from "../fable_modules/fable-library-js.4.24.0/Util.js";

export class Props extends Record {
    constructor(ModuleId, ModuleName, Birthday, Action, DownloadType, DownloadTimePeriod) {
        super();
        this.ModuleId = ModuleId;
        this.ModuleName = ModuleName;
        this.Birthday = Birthday;
        this.Action = Action;
        this.DownloadType = DownloadType;
        this.DownloadTimePeriod = DownloadTimePeriod;
    }
}

export function Props_$reflection() {
    return record_type("Components.CSVHelper.Props", [], Props, () => [["ModuleId", string_type], ["ModuleName", string_type], ["Birthday", option_type(class_type("System.DateTime"))], ["Action", lambda_type(tuple_type(string_type, string_type, int32_type, int32_type), unit_type)], ["DownloadType", option_type(string_type)], ["DownloadTimePeriod", DownloadTimePeriod_$reflection()]]);
}

export const subButtonBar = FunctionComponent_Of_60E46241((props) => box$0027(ofArray([new Common_GenericOption(2, [ofArray([new Modifier_IModifier(0, [props.BackgroundColor]), new Modifier_IModifier(1, [props.TextColor])])]), new Common_GenericOption(1, [singleton(["style", {
    cursor: "pointer",
}])])]), singleton(level(empty(), singleton(item(empty(), singleton(props.Name)))))), undefined, undefined, undefined, "subButtonBar", "/Users/royalefougard/workspace/iotpool-front3/src/Components/CSVHelper.fs", 24);

export function getWeekFromMondaytoMonday(year, month) {
    let added;
    if (month === 1) {
        const firstYearMonthDay = create(year, 1, 1);
        added = ((day(addDays(firstYearMonthDay, (7 - (dayOfWeek(firstYearMonthDay) - 1)) % 7)) > 4) ? 1 : 0);
    }
    else {
        added = 0;
    }
    if (month === 12) {
        const firstMonthDay = create(year, month, 1);
        const firstMonthMonday = addDays(firstMonthDay, (7 - (dayOfWeek(firstMonthDay) - 1)) % 7);
        const firstNextMonthDay = create(year + 1, 1, 1);
        const firstNextMonthMonday = addDays(firstNextMonthDay, (7 - (dayOfWeek(firstNextMonthDay) - 1)) % 7);
        return ~~Math.ceil(days(op_Subtraction(firstNextMonthMonday, firstMonthMonday)) / 7) | 0;
    }
    else {
        const firstMonthDay_1 = create(year, month, 1);
        const firstMonthMonday_1 = addDays(firstMonthDay_1, (7 - (dayOfWeek(firstMonthDay_1) - 1)) % 7);
        const firstNextMonthDay_1 = create(year, month + 1, 1);
        const diff = days(op_Subtraction(addDays(firstNextMonthDay_1, (7 - (dayOfWeek(firstNextMonthDay_1) - 1)) % 7), firstMonthMonday_1)) | 0;
        return (~~Math.ceil(diff / 7) + added) | 0;
    }
}

export const getWeeksForMonth = FunctionComponent_Of_60E46241((props) => {
    const min = sum(map((month) => getWeekFromMondaytoMonday(props.Year, month), filter((x) => (x < props.MonthIndex), toList(rangeDouble(1, 1, 12)))), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) | 0;
    return columns(empty(), map_1((x_2) => column(empty(), singleton(subButtonBar({
        BackgroundColor: new Color_IColor(5, []),
        Name: toText(printf(" %i "))(x_2),
        TextColor: new Color_IColor(3, []),
    }))), toList(rangeDouble(min + 1, 1, min + getWeekFromMondaytoMonday(props.Year, props.MonthIndex)))));
}, undefined, undefined, undefined, "getWeeksForMonth", "/Users/royalefougard/workspace/iotpool-front3/src/Components/CSVHelper.fs", 76);

export function view(props) {
    let monthList, matchValue, year_3, b, currentMonth, firstYear, thisVeryYear, list_4, diff;
    const toBox = FunctionComponent_Of_60E46241((bx) => columns(singleton(new Option(9, [singleton(new DOMAttr(40, [(_arg) => {
        props.Action([props.ModuleId, props.ModuleName, bx.Month, bx.Year]);
    }]))])), bx.Elements), undefined, undefined, undefined, "view", "/Users/royalefougard/workspace/iotpool-front3/src/Components/CSVHelper.fs", 99);
    const now = now_1();
    return Seq_split(6, (monthList = mapIndexed((m, month) => [m + 1, month], ofArray(["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"])), (matchValue = props.Birthday, (matchValue == null) ? ((year_3 = (year_4(now) | 0), map_1((tupledArg_8) => toBox({
        Elements: singleton(UI_subButtonBar(toText(printf("%s %i"))(tupledArg_8[1])(year_3))),
        Month: tupledArg_8[0],
        Year: year_3,
    }), take(month_2(now), monthList)))) : ((b = matchValue, (currentMonth = (((month_2(b) > 0) ? (month_2(b) - 1) : 12) | 0), (firstYear = ((year_4(b) === year_4(now)) ? empty() : map_1((tupledArg) => [tupledArg[0], tupledArg[1], year_4(b)], skip(currentMonth, monthList))), (thisVeryYear = ((year_4(b) === year_4(now)) ? map_1((tupledArg_1) => [tupledArg_1[0], tupledArg_1[1], year_4(now)], (list_4 = skip(currentMonth, monthList), take(month_2(now) - currentMonth, list_4))) : map_1((tupledArg_2) => [tupledArg_2[0], tupledArg_2[1], year_4(now)], take(month_2(now), monthList))), map_1((tupledArg_6) => {
        let s;
        return mediaContents({
            Color: new Color_IColor(2, []),
            Contents: map_1((tupledArg_7) => {
                let matchValue_1, dt;
                const m_7 = tupledArg_7[0] | 0;
                const label_6 = tupledArg_7[1];
                const year_2 = tupledArg_7[2] | 0;
                return toBox({
                    Elements: (matchValue_1 = props.DownloadType, (matchValue_1 != null) ? ((dt = matchValue_1, ofArray([column(empty(), singleton(subButtonBar({
                        BackgroundColor: new Color_IColor(4, []),
                        Name: toText(printf("%s : %s %i"))(dt)(label_6)(year_2),
                        TextColor: new Color_IColor(0, []),
                    }))), column(empty(), singleton(getWeeksForMonth({
                        MonthIndex: m_7,
                        Year: year_2,
                    })))]))) : singleton(column(empty(), singleton(UI_subButtonBar(toText(printf("%s %i"))(label_6)(year_2)))))),
                    Month: m_7,
                    Year: year_2,
                });
            }, tupledArg_6[1]),
            Description: singleton((s = "Appuyez sur un bouton pour télécharger le fichier qui vous intéresse : le mois complet ou uniquement la semaine", s)),
            Icon: new Fa_IconOption(11, ["fas fa-calendar-week"]),
            Title: toText(printf("%i"))(tupledArg_6[0]),
        });
    }, reverse(List_groupBy((tupledArg_5) => tupledArg_5[2], append(firstYear, append((diff = ((year_4(now) - year_4(b)) | 0), (diff === 0) ? empty() : ((diff === 1) ? empty() : ((diff === 2) ? map_1((tupledArg_3) => [tupledArg_3[0], tupledArg_3[1], year_4(b) + 1], monthList) : concat(map_1((i) => map_1((tupledArg_4) => [tupledArg_4[0], tupledArg_4[1], year_4(b) + i], monthList), toList(rangeDouble(0, 1, diff - 1))))))), thisVeryYear)), {
        Equals: (x_1, y) => (x_1 === y),
        GetHashCode: numberHash,
    })))))))))));
}

