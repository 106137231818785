import { Union } from "../fable-library-js.4.24.0/Types.js";
import { Color_ofColor, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_5A13F3A3, Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z749EF786, Common_GenericOptions__ToReactElement_12AABBE2, Modifier_IModifier_$reflection, Size_ISize_$reflection, Color_IColor_$reflection } from "../Fulma.2.16.0/Common.fs.js";
import { union_type, list_type, float64_type, string_type, lambda_type, unit_type, class_type, bool_type } from "../fable-library-js.4.24.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../fable-library-js.4.24.0/MapUtil.js";
import { Reflection_getCaseName } from "../Fulma.2.16.0/./Common.fs.js";
import { Prop, DOMAttr, HTMLAttr } from "../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { equals } from "../fable-library-js.4.24.0/Util.js";
import { singleton } from "../fable-library-js.4.24.0/List.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Color", "Size", "is-fullwidth", "is-circle", "Disabled", "OnChange", "Id", "Min", "Max", "Step", "Value", "DefaultValue", "ValueOrDefault", "IsVertical", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Extensions.Wikiki.Slider.Option", [], Option, () => [[["Item", Color_IColor_$reflection()]], [["Item", Size_ISize_$reflection()]], [], [], [["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", string_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", string_type]], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <input class="slider"/>
 */
export function slider(options) {
    return Common_GenericOptions__ToReactElement_12AABBE2(Common_GenericOptions_Parse_Z749EF786(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 2:
            case 3:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 4:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(79, [option.fields[0]]));
            case 10:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(161, [option.fields[0]]));
            case 7:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(119, [option.fields[0]]));
            case 8:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(114, [option.fields[0]]));
            case 9:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(154, [option.fields[0]]));
            case 5:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(9, [option.fields[0]]));
            case 6:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(99, [option.fields[0]]));
            case 13:
                return Common_GenericOptions__AddProp_7BFEDA81(result, ["orient", "vertical"]);
            case 11:
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(1, [option.fields[0]]));
            case 12: {
                const valueOrDefault = option.fields[0];
                return Common_GenericOptions__AddProp_7BFEDA81(result, new Prop(1, [(e) => {
                    if (!(e == null) && !equals(e.value, valueOrDefault)) {
                        e.value = valueOrDefault;
                    }
                }]));
            }
            case 14:
                return Common_GenericOptions__AddProps_5A13F3A3(result, option.fields[0]);
            case 15:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 16:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
        }
    }, "slider", singleton(new HTMLAttr(159, ["range"]))), (props_1) => react.createElement("input", keyValueList(props_1, 1)));
}

