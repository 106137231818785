import { Record } from "./fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, obj_type, string_type } from "./fable_modules/fable-library-js.4.24.0/Reflection.js";

export class FileInfo extends Record {
    constructor(name, blob) {
        super();
        this.name = name;
        this.blob = blob;
    }
}

export function FileInfo_$reflection() {
    return record_type("Fable.Import.JSZip.FileInfo", [], FileInfo, () => [["name", string_type], ["blob", obj_type]]);
}

