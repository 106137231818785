import { Common_parseOptions, Common_GenericOptions_Parse_Z749EF786, Common_GenericOptions__ToReactElement_Z6A76A149 } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.24.0/MapUtil.js";

/**
 * Generate <div class="box"></div>
 */
export function box$0027(options, children) {
    return Common_GenericOptions__ToReactElement_Z6A76A149(Common_GenericOptions_Parse_Z749EF786(options, Common_parseOptions, "box"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

