import { toString, Record, Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, bool_type, option_type, array_type, anonRecord_type, list_type, tuple_type, int32_type, string_type, union_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Tag_$reflection, Sensor_Status_$reflection } from "../Shared.fs.js";
import { toArray, take, item, mapIndexed, singleton, append, ofArray, length, sortByDescending, map as map_2, isEmpty as isEmpty_1, empty, filter as filter_2, sort, sortBy } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { numberHash, equals, comparePrimitives, stringHash, compare } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { SelectedKinds_SelectedKinds, SelectedKinds_set, SelectedSensors_SelectedSensors, SelectedSensors_set, SelectedTags_SelectedTags, SelectedTags_set, SelectDisplayMode_SelectDisplayMode, SelectDisplayMode_set, SelectedTags_load, SelectedKinds_load, SelectedSensors_load, Props_load, SelectDisplayMode_load } from "../SessionStorage.fs.js";
import { toText, join, replace, split, printf, toConsole } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { sum, head, exists, map, concat, toList, isEmpty, filter as filter_1 } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { List_distinctBy, List_groupBy, distinct } from "../fable_modules/fable-library-js.4.24.0/Seq2.js";
import { unwrap, value as value_2 } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { map as map_1 } from "../fable_modules/fable-library-js.4.24.0/Array.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { year as year_1, month as month_1, toLocalTime, subtract, now as now_3, toString as toString_1 } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { fromDate, toUnixTimeSeconds } from "../fable_modules/fable-library-js.4.24.0/DateOffset.js";
import { compare as compare_1, fromInt32, toInt64 } from "../fable_modules/fable-library-js.4.24.0/BigInt.js";
import { split as split_1, isMatch, escape, create } from "../fable_modules/fable-library-js.4.24.0/RegExp.js";
import { Types_SensorKind_get_toLabel } from "../../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { Inside_Str, Inside_Heading_h1, Inside_Section } from "../GogoFulma.fs.js";
import { Option as Option_7, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Size_ISize, TextSize_Option, Common_GenericOption, TextAlignment_Option, Modifier_IModifier, Color_IColor, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_1, label as label_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Option as Option_2, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import * as react from "react";
import Grid from "./SensorComponents/Grid.tsx";
import { Option as Option_3, switch$ } from "../fable_modules/Fulma.Extensions.Wikiki.Switch.2.0.2/Switch.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { FunctionComponent_Of_60E46241 } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { List_Option, list as list_33, Option as Option_4, tag as tag_46 } from "../fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.24.0/MapUtil.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { Option as Option_5, content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { days, hours } from "../fable_modules/fable-library-js.4.24.0/TimeSpan.js";
import { localFormat } from "../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { h6, Option as Option_6, h4 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { header, Option as Option_8, message } from "../fable_modules/Fulma.2.16.0/Components/Message.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { item as item_1, right, left, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { Option as Option_9, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Seq_split } from "../Utils.fs.js";
import { parse } from "../fable_modules/fable-library-js.4.24.0/Int32.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.24.0/Range.js";
import { getWeekFromMondaytoMonday } from "../Components/CSVHelper.fs.js";
import MultiSelect from "./SensorComponents/MultiSelect.tsx";
import { Option as Option_10, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export class Types_Filter extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Label"];
    }
}

export function Types_Filter_$reflection() {
    return union_type("Page.Status.Types.Filter", [], Types_Filter, () => [[]]);
}

export class Types_ToggleState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Up", "Down"];
    }
}

export function Types_ToggleState_$reflection() {
    return union_type("Page.Status.Types.ToggleState", [], Types_ToggleState, () => [[], []]);
}

export class Types_ToggleKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NumSerie", "Name", "Kind", "Status"];
    }
}

export function Types_ToggleKind_$reflection() {
    return union_type("Page.Status.Types.ToggleKind", [], Types_ToggleKind, () => [[], [], [], []]);
}

export class Types_ReportKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CSV", "PDF"];
    }
}

export function Types_ReportKind_$reflection() {
    return union_type("Page.Status.Types.ReportKind", [], Types_ReportKind, () => [[], []]);
}

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Load", "GetSensor", "SearchTextUpdated", "FilterByTag", "ToggleDisplayMode", "ToggleDisplayVideoDoc", "UpdateStartDate", "UpdateEndDate", "ToggleTags", "ToggleDownload", "DownloadMonthReport", "DownloadMonthReportFor", "DownloadWeekReportFor", "DownloadWeekReport", "Toggle", "FilterByNames", "FilterByTags", "FilterByKinds", "DownloadReport", "DownloadReportFor"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Status.Types.Msg", [], Types_Msg, () => [[], [["Item", string_type]], [["Item1", Types_Filter_$reflection()], ["Item2", string_type]], [["Item", string_type]], [], [], [["Item", string_type]], [["Item", string_type]], [], [], [["Item", int32_type]], [["Item1", list_type(tuple_type(string_type, string_type))], ["Item2", int32_type]], [["Item1", list_type(tuple_type(string_type, string_type))], ["Item2", int32_type]], [["Item", int32_type]], [["Item", Types_ToggleKind_$reflection()]], [["Item", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item1", Types_ReportKind_$reflection()], ["Item2", string_type], ["Item3", string_type]], [["Item1", list_type(tuple_type(string_type, string_type))], ["Item2", Types_ReportKind_$reflection()], ["Item3", string_type], ["Item4", string_type]]]);
}

export class Types_DisplayMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Table", "Tiles", "Grouped"];
    }
}

export function Types_DisplayMode_$reflection() {
    return union_type("Page.Status.Types.DisplayMode", [], Types_DisplayMode, () => [[], [], []]);
}

export class Types_Model extends Record {
    constructor(Data, VisibleResources, Error$, AdminView, CurrentSearches, DisplayMode, Tags, FilterTags, CurrentTag, ToggleName, ToggleKind, ToggleStatus, ToggleNumSerie, SelectedSensors, SelectedTags, SelectedKinds, DisplayTags, IsVideoDocDisplayed, VideoUrl, PDFUrl, GraphStartDate, GraphEndDate, DisplayDownload) {
        super();
        this.Data = Data;
        this.VisibleResources = VisibleResources;
        this.Error = Error$;
        this.AdminView = AdminView;
        this.CurrentSearches = CurrentSearches;
        this.DisplayMode = DisplayMode;
        this.Tags = Tags;
        this.FilterTags = FilterTags;
        this.CurrentTag = CurrentTag;
        this.ToggleName = ToggleName;
        this.ToggleKind = ToggleKind;
        this.ToggleStatus = ToggleStatus;
        this.ToggleNumSerie = ToggleNumSerie;
        this.SelectedSensors = SelectedSensors;
        this.SelectedTags = SelectedTags;
        this.SelectedKinds = SelectedKinds;
        this.DisplayTags = DisplayTags;
        this.IsVideoDocDisplayed = IsVideoDocDisplayed;
        this.VideoUrl = VideoUrl;
        this.PDFUrl = PDFUrl;
        this.GraphStartDate = GraphStartDate;
        this.GraphEndDate = GraphEndDate;
        this.DisplayDownload = DisplayDownload;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Status.Types.Model", [], Types_Model, () => [["Data", list_type(Sensor_Status_$reflection())], ["VisibleResources", list_type(Sensor_Status_$reflection())], ["Error", option_type(string_type)], ["AdminView", bool_type], ["CurrentSearches", list_type(tuple_type(Types_Filter_$reflection(), string_type, list_type(string_type)))], ["DisplayMode", Types_DisplayMode_$reflection()], ["Tags", option_type(list_type(Tag_$reflection()))], ["FilterTags", option_type(list_type(string_type))], ["CurrentTag", option_type(string_type)], ["ToggleName", Types_ToggleState_$reflection()], ["ToggleKind", Types_ToggleState_$reflection()], ["ToggleStatus", Types_ToggleState_$reflection()], ["ToggleNumSerie", Types_ToggleState_$reflection()], ["SelectedSensors", array_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["SelectedTags", array_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["SelectedKinds", array_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["DisplayTags", bool_type], ["IsVideoDocDisplayed", bool_type], ["VideoUrl", option_type(string_type)], ["PDFUrl", option_type(string_type)], ["GraphStartDate", string_type], ["GraphEndDate", string_type], ["DisplayDownload", bool_type]]);
}

export function Types_initialModel(list, startDate, endDate, user) {
    let kinds, tags_1, names;
    const list_2 = sortBy((s) => s.Title, list, {
        Compare: compare,
    });
    let displayMode;
    try {
        const props = SelectDisplayMode_load();
        toConsole(printf("props: %A"))(props);
        displayMode = ((props.DisplayMode === "table") ? (new Types_DisplayMode(0, [])) : (new Types_DisplayMode(1, [])));
    }
    catch (matchValue) {
        displayMode = (new Types_DisplayMode(1, []));
    }
    let tags;
    try {
        tags = Props_load().Tags;
    }
    catch (matchValue_1) {
        tags = undefined;
    }
    let admin;
    if (user == null) {
        admin = false;
    }
    else {
        const matchValue_2 = user.UserKind;
        switch (matchValue_2.tag) {
            case 1:
            case 0: {
                admin = true;
                break;
            }
            default:
                admin = false;
        }
    }
    let filtertags;
    const list_3 = filter_1((status) => (status.Tags != null), list_2);
    filtertags = (isEmpty(list_3) ? undefined : sort(toList(distinct(filter_1((tag) => (tag.trim().length > 0), concat(map((status_1) => split(value_2(status_1.Tags), [" "], undefined, 0), list_3))), {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    })), {
        Compare: comparePrimitives,
    }));
    let selectedSensors_1;
    try {
        selectedSensors_1 = SelectedSensors_load().Sensors;
    }
    catch (matchValue_3) {
        selectedSensors_1 = [];
    }
    let selectedKinds_1;
    try {
        selectedKinds_1 = SelectedKinds_load().Kinds;
    }
    catch (matchValue_4) {
        selectedKinds_1 = [];
    }
    let selectedTags_1;
    try {
        selectedTags_1 = SelectedTags_load().Tags;
    }
    catch (matchValue_5) {
        selectedTags_1 = [];
    }
    return new Types_Model(list_2, (selectedSensors_1.length <= 0) ? ((selectedTags_1.length <= 0) ? ((selectedKinds_1.length <= 0) ? list_2 : ((kinds = map_1((x_3) => x_3.value, selectedKinds_1), filter_2((r) => exists((k) => (k === toString(r.SensorKind)), kinds), list_2)))) : ((tags_1 = map_1((x_4) => x_4.value, selectedTags_1), filter_2((r_1) => {
        const matchValue_6 = r_1.Tags;
        if (matchValue_6 != null) {
            const currentTag = matchValue_6;
            return exists((t) => (t === currentTag), tags_1);
        }
        else {
            return false;
        }
    }, list_2)))) : ((names = map_1((x_5) => x_5.value, selectedSensors_1), filter_2((r_2) => exists((n) => (n === r_2.Name), names), list_2))), undefined, admin, empty(), displayMode, tags, filtertags, undefined, new Types_ToggleState(0, []), new Types_ToggleState(0, []), new Types_ToggleState(0, []), new Types_ToggleState(0, []), selectedSensors_1, selectedTags_1, selectedKinds_1, false, false, "https://youtu.be/PakNQYFA_P8?si=2H1-96O9YYPtySnF", "https://www.mginstruments.fr/sites/default/files/01-fiche_presentation_v2.pdf", startDate, endDate, false);
}

export function State_update(msg, model) {
    let matchValue_18, tag_1;
    switch (msg.tag) {
        case 5: {
            toConsole(printf("ToggleDisplayVideoDoc"));
            let matchResult;
            if (model.VideoUrl != null) {
                if (model.PDFUrl != null) {
                    matchResult = 0;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, !model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                default:
                    return [model, Cmd_none()];
            }
        }
        case 6: {
            const date = msg.fields[0];
            if (date > model.GraphEndDate) {
                return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphEndDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, date, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
        }
        case 7: {
            const date_1 = msg.fields[0];
            if (date_1 < model.GraphStartDate) {
                return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphStartDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                const now = toString_1(now_3(), "yyyy-MM-dd");
                if (date_1 > now) {
                    return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, now, model.DisplayDownload), Cmd_none()];
                }
                else {
                    return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, date_1, model.DisplayDownload), Cmd_none()];
                }
            }
        }
        case 18:
            if (isEmpty_1(model.VisibleResources)) {
                return [model, Cmd_none()];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(19, [map_2((status) => [status.Name, value_2(status.Title)], model.VisibleResources), msg.fields[0], msg.fields[1], msg.fields[2]]))];
            }
        case 14: {
            const kind_1 = msg.fields[0];
            const matchValue_4 = model.DisplayMode;
            switch (matchValue_4.tag) {
                case 1:
                    return [model, Cmd_none()];
                case 0:
                    switch (kind_1.tag) {
                        case 1:
                            if (model.ToggleName.tag === 1) {
                                return [new Types_Model(model.Data, sortBy((s_3) => {
                                    if (s_3.Title != null) {
                                        return value_2(s_3.Title);
                                    }
                                    else {
                                        return "";
                                    }
                                }, model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, new Types_ToggleState(0, []), model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                            else {
                                return [new Types_Model(model.Data, sortByDescending((s_2) => {
                                    if (s_2.Title != null) {
                                        return value_2(s_2.Title);
                                    }
                                    else {
                                        return "";
                                    }
                                }, model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, new Types_ToggleState(1, []), model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                        case 0:
                            if (model.ToggleNumSerie.tag === 1) {
                                return [new Types_Model(model.Data, sortBy((s_5) => s_5.Name, model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, new Types_ToggleState(0, []), model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                            else {
                                return [new Types_Model(model.Data, sortByDescending((s_4) => s_4.Name, model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, new Types_ToggleState(1, []), model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                        case 2:
                            if (model.ToggleKind.tag === 1) {
                                return [new Types_Model(model.Data, sortBy((s_7) => toString(s_7.SensorKind), model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, new Types_ToggleState(0, []), model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                            else {
                                return [new Types_Model(model.Data, sortByDescending((s_6) => toString(s_6.SensorKind), model.VisibleResources, {
                                    Compare: comparePrimitives,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, new Types_ToggleState(1, []), model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                        default:
                            if (model.ToggleStatus.tag === 1) {
                                return [new Types_Model(model.Data, sortBy((s_1) => {
                                    if (s_1.Date != null) {
                                        return toUnixTimeSeconds(fromDate(value_2(s_1.Date)));
                                    }
                                    else {
                                        return toInt64(fromInt32(0));
                                    }
                                }, model.VisibleResources, {
                                    Compare: compare_1,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, new Types_ToggleState(0, []), model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                            else {
                                return [new Types_Model(model.Data, sortByDescending((s) => {
                                    if (s.Date != null) {
                                        return toUnixTimeSeconds(fromDate(value_2(s.Date)));
                                    }
                                    else {
                                        return toInt64(fromInt32(0));
                                    }
                                }, model.VisibleResources, {
                                    Compare: compare_1,
                                }), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, new Types_ToggleState(1, []), model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                            }
                    }
                default:
                    return [model, Cmd_none()];
            }
        }
        case 8:
            return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, !model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
        case 9:
            return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, !model.DisplayDownload), Cmd_none()];
        case 10: {
            const monthIndex = msg.fields[0] | 0;
            if (monthIndex < 0) {
                return [model, Cmd_none()];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(11, [map_2((status_1) => {
                    let matchValue_9;
                    return [status_1.Name, replace(replace(replace(replace(replace(replace(replace(replace((matchValue_9 = status_1.Title, (matchValue_9 == null) ? status_1.Name : matchValue_9), "<", "_"), ">", "_"), ":", "_"), "\"", "_"), "/", "_"), "|", "_"), "?", "_"), "*", "_")];
                }, model.VisibleResources), monthIndex]))];
            }
        }
        case 13: {
            const monthIndex_1 = msg.fields[0] | 0;
            if (monthIndex_1 < 0) {
                return [model, Cmd_none()];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(12, [map_2((status_2) => {
                    let matchValue_10;
                    return [status_2.Name, replace(replace(replace(replace(replace(replace(replace(replace((matchValue_10 = status_2.Title, (matchValue_10 == null) ? status_2.Name : matchValue_10), "<", "_"), ">", "_"), ":", "_"), "\"", "_"), "/", "_"), "|", "_"), "?", "_"), "*", "_")];
                }, model.VisibleResources), monthIndex_1]))];
            }
        }
        case 4: {
            const patternInput = equals(model.DisplayMode, new Types_DisplayMode(0, [])) ? [new Types_DisplayMode(1, []), false] : [new Types_DisplayMode(0, []), true];
            const status_3 = patternInput[1];
            try {
                SelectDisplayMode_set(new SelectDisplayMode_SelectDisplayMode(status_3 ? "table" : "tiles"));
            }
            catch (matchValue_11) {
                SelectDisplayMode_set(new SelectDisplayMode_SelectDisplayMode(status_3 ? "table" : "tiles"));
            }
            return [new Types_Model(model.Data, model.VisibleResources, model.Error, model.AdminView, model.CurrentSearches, patternInput[0], model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
        }
        case 16: {
            const list_14 = msg.fields[0];
            if (list_14.length <= 0) {
                SelectedTags_set(new SelectedTags_SelectedTags([]));
                return [new Types_Model(model.Data, model.Data, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], [], model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                const selectedTags = map_1((x_8) => x_8.value, list_14);
                const filtered = filter_2((r) => {
                    const matchValue_12 = r.Tags;
                    if (matchValue_12 != null) {
                        const currentTag = matchValue_12;
                        return exists((t_20) => (t_20 === currentTag), selectedTags);
                    }
                    else {
                        return false;
                    }
                }, model.Data);
                SelectedSensors_set(new SelectedSensors_SelectedSensors([]));
                SelectedTags_set(new SelectedTags_SelectedTags(list_14));
                return [new Types_Model(model.Data, filtered, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], list_14, [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
        }
        case 3: {
            const tag = msg.fields[0];
            const certificates = filter_2((r_1) => (length(filter_2((ok_3) => ok_3, map_2((tupledArg) => {
                let matchValue_14;
                const matchValue_13 = tupledArg[1].trim();
                if (matchValue_13.length <= 0) {
                    return true;
                }
                else {
                    const regex = create(join("", map((x_9) => (("(?=.*" + escape(x_9)) + ")"), tupledArg[2])), 1);
                    if (((matchValue_14 = r_1.Title, (matchValue_14 == null) ? false : isMatch(regex, matchValue_14))) ? true : isMatch(regex, r_1.Name)) {
                        return true;
                    }
                    else {
                        return isMatch(regex, replace(Types_SensorKind_get_toLabel()(r_1.SensorKind), "-", ""));
                    }
                }
            }, model.CurrentSearches))) === length(model.CurrentSearches)), model.Data);
            const matchValue_15 = model.CurrentTag;
            if (matchValue_15 == null) {
                return [new Types_Model(model.Data, filter_2((r_4) => {
                    const matchValue_17 = r_4.Tags;
                    if (matchValue_17 != null) {
                        const tags_1 = matchValue_17;
                        return tags_1.indexOf(tag) >= 0;
                    }
                    else {
                        return false;
                    }
                }, certificates), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, tag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], model.SelectedTags, [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                const ctag = matchValue_15;
                if (ctag.indexOf(tag) >= 0) {
                    return [new Types_Model(model.Data, certificates, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, undefined, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, model.SelectedSensors, model.SelectedTags, model.SelectedKinds, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                }
                else {
                    return [new Types_Model(model.Data, filter_2((r_3) => {
                        const matchValue_16 = r_3.Tags;
                        if (matchValue_16 != null) {
                            const tags = matchValue_16;
                            return tags.indexOf(tag) >= 0;
                        }
                        else {
                            return false;
                        }
                    }, certificates), model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, tag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], model.SelectedTags, [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
                }
            }
        }
        case 15: {
            const list_21 = msg.fields[0];
            if (list_21.length <= 0) {
                SelectedSensors_set(new SelectedSensors_SelectedSensors([]));
                return [new Types_Model(model.Data, model.Data, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], [], [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                const names = map_1((x_10) => x_10.value, list_21);
                const certificates_3 = filter_2((r_5) => exists((n) => (n === r_5.Name), names), model.Data);
                SelectedSensors_set(new SelectedSensors_SelectedSensors(list_21));
                SelectedTags_set(new SelectedTags_SelectedTags([]));
                return [new Types_Model(model.Data, certificates_3, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, list_21, [], [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
        }
        case 17: {
            const list_23 = msg.fields[0];
            if (list_23.length <= 0) {
                SelectedKinds_set(new SelectedKinds_SelectedKinds([]));
                return [new Types_Model(model.Data, model.Data, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], [], [], model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
            else {
                const kinds = map_1((x_11) => x_11.value, list_23);
                const certificates_4 = filter_2((r_6) => exists((k_1) => (k_1 === toString(r_6.SensorKind)), kinds), model.Data);
                SelectedKinds_set(new SelectedKinds_SelectedKinds(list_23));
                SelectedTags_set(new SelectedTags_SelectedTags([]));
                return [new Types_Model(model.Data, certificates_4, model.Error, model.AdminView, model.CurrentSearches, model.DisplayMode, model.Tags, model.FilterTags, model.CurrentTag, model.ToggleName, model.ToggleKind, model.ToggleStatus, model.ToggleNumSerie, [], [], list_23, model.DisplayTags, model.IsVideoDocDisplayed, model.VideoUrl, model.PDFUrl, model.GraphStartDate, model.GraphEndDate, model.DisplayDownload), Cmd_none()];
            }
        }
        case 2: {
            const text_3 = msg.fields[1];
            const kind_3 = msg.fields[0];
            const terms_3 = ofArray(split_1(text_3, "(?:\\s*,\\s*)|\\s+"));
            const updatedSearches = append(filter_2((tupledArg_3) => !equals(tupledArg_3[0], kind_3), model.CurrentSearches), singleton([kind_3, text_3, terms_3]));
            let model_4;
            const searches = updatedSearches;
            const model_3 = model;
            model_4 = (new Types_Model(model_3.Data, (matchValue_18 = model_3.CurrentTag, (matchValue_18 == null) ? filter_2((r_10) => (length(filter_2((ok_11) => ok_11, map_2((tupledArg_2) => {
                let matchValue_23;
                const matchValue_22 = tupledArg_2[1].trim();
                if (matchValue_22.length <= 0) {
                    return true;
                }
                else {
                    const regex_2 = create(join("", map((x_13) => (("(?=.*" + escape(x_13)) + ")"), tupledArg_2[2])), 1);
                    if (((matchValue_23 = r_10.Title, (matchValue_23 == null) ? false : isMatch(regex_2, matchValue_23))) ? true : isMatch(regex_2, r_10.Name)) {
                        return true;
                    }
                    else {
                        return isMatch(regex_2, replace(Types_SensorKind_get_toLabel()(r_10.SensorKind), "-", ""));
                    }
                }
            }, searches))) === length(searches)), model_3.Data) : ((tag_1 = matchValue_18, filter_2((r_8) => (length(filter_2((ok_7) => ok_7, map_2((tupledArg_1) => {
                let matchValue_21;
                const matchValue_20 = tupledArg_1[1].trim();
                if (matchValue_20.length <= 0) {
                    return true;
                }
                else {
                    const regex_1 = create(join("", map((x_12) => (("(?=.*" + escape(x_12)) + ")"), tupledArg_1[2])), 1);
                    if (((matchValue_21 = r_8.Title, (matchValue_21 == null) ? false : isMatch(regex_1, matchValue_21))) ? true : isMatch(regex_1, r_8.Name)) {
                        return true;
                    }
                    else {
                        return isMatch(regex_1, replace(Types_SensorKind_get_toLabel()(r_8.SensorKind), "-", ""));
                    }
                }
            }, searches))) === length(searches)), filter_2((r_7) => {
                const matchValue_19 = r_7.Tags;
                if (matchValue_19 != null) {
                    const tags_2 = matchValue_19;
                    return tags_2.indexOf(tag_1) >= 0;
                }
                else {
                    return false;
                }
            }, model_3.Data))))), model_3.Error, model_3.AdminView, model_3.CurrentSearches, model_3.DisplayMode, model_3.Tags, model_3.FilterTags, model_3.CurrentTag, model_3.ToggleName, model_3.ToggleKind, model_3.ToggleStatus, model_3.ToggleNumSerie, model_3.SelectedSensors, model_3.SelectedTags, model_3.SelectedKinds, model_3.DisplayTags, model_3.IsVideoDocDisplayed, model_3.VideoUrl, model_3.PDFUrl, model_3.GraphStartDate, model_3.GraphEndDate, model_3.DisplayDownload));
            return [new Types_Model(model_4.Data, model_4.VisibleResources, model_4.Error, model_4.AdminView, updatedSearches, model_4.DisplayMode, model_4.Tags, model_4.FilterTags, model_4.CurrentTag, model_4.ToggleName, model_4.ToggleKind, model_4.ToggleStatus, model_4.ToggleNumSerie, model_4.SelectedSensors, model_4.SelectedTags, model_4.SelectedKinds, model_4.DisplayTags, model_4.IsVideoDocDisplayed, model_4.VideoUrl, model_4.PDFUrl, model_4.GraphStartDate, model_4.GraphEndDate, model_4.DisplayDownload), Cmd_none()];
        }
        default:
            return [model, Cmd_none()];
    }
}

export function View_root(model, dispatch) {
    let filter, label, currentSearchValue, found, props, props_46, children_44, props_44, children_42, props_34, props_36, children_34, props_38, children_36, props_40, children_38, props_42, children_40, props_71, children_69, source_6, year, props_79, children_77, props_83, props_87, props_91, props_93, props_95, children_94;
    if (isEmpty_1(model.Data)) {
        return Inside_Section(singleton(Inside_Heading_h1(singleton(Inside_Str("Aucun capteur n\'est pour l\'instant référencé sur votre compte.")))));
    }
    else {
        let filters;
        const tableMode = equals(model.DisplayMode, new Types_DisplayMode(0, []));
        const switchLabel = equals(model.DisplayMode, new Types_DisplayMode(0, [])) ? "Mode Tableau" : "Mode Blocs";
        filters = columns(empty(), ofArray([column(singleton(new Option(0, [new Screen(0, []), new ISize(3, [])])), ofArray([(filter = (new Types_Filter()), (label = "Filtrez ou recherchez vos capteurs en tapant un *numéro de série*, un *type* ou les premières lettres du *nom* de votre capteur", (currentSearchValue = ((found = filter_2((tupledArg) => equals(filter, tupledArg[0]), model.CurrentSearches), isEmpty_1(found) ? "" : head(found)[1])), div(empty(), ofArray([label_1(singleton(new Option_1(4, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])), singleton(label)), div_1(empty(), singleton(columns(empty(), singleton(column(empty(), singleton(input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(12, ["Ex: 377BF0 ou CO2 ou Clinique de XXX, etc..."]), new Option_2(8, [currentSearchValue]), new Option_2(13, [(ev) => {
            dispatch(new Types_Msg(2, [filter, Browser_Types_Event__Event_get_Value(ev)]));
        }])]))))))))]))))), (props = {
            name: "toto",
        }, react.createElement(Grid, props))])), column(empty(), singleton(div(empty(), ofArray([label_1(singleton(new Option_1(4, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])), singleton("Choisissez votre mode d\'affichage")), column(singleton(new Option(4, [singleton(new Modifier_IModifier(1, [new Color_IColor(3, [])]))])), singleton(switch$(ofArray([new Option_3(6, [tableMode]), new Option_3(0, [new Color_IColor(5, [])]), new Option_3(2, []), new Option_3(12, ["tableSwitch"]), new Option_3(9, [singleton(new DOMAttr(9, [(_arg_4) => {
            dispatch(new Types_Msg(4, []));
        }]))])]), singleton(switchLabel))))]))))]));
        const box = FunctionComponent_Of_60E46241((props_2) => {
            let props_3, matchValue_2, d_1, props_10;
            let details;
            let statusMessage;
            const matchValue_1 = props_2.TagMessage;
            if (matchValue_1 == null) {
                statusMessage = react.createElement(react.Fragment, {});
            }
            else {
                const t = matchValue_1;
                statusMessage = tag_46(singleton(new Option_4(1, [props_2.Color[0]])), singleton((props_3 = [["style", {
                    color: "white",
                }]], react.createElement("strong", keyValueList(props_3, 1), t))));
            }
            const detailList = map_2((d) => tag_46(singleton(new Option_4(1, [props_2.Color[0]])), singleton(toText(printf("📋%s"))(d))), props_2.Details);
            const allDetails_1 = map_2((d_2) => {
                const props_7 = [["style", {
                    display: "flex",
                    flexGrow: 1,
                    textAlign: "center",
                    width: "100%",
                }]];
                return react.createElement("div", keyValueList(props_7, 1), d_2);
            }, append(singleton((matchValue_2 = props_2.Date, (matchValue_2 == null) ? react.createElement(react.Fragment, {}) : ((d_1 = matchValue_2, tag_46(singleton(new Option_4(1, [props_2.Color[0]])), singleton(toText(printf("🕑%s"))(d_1))))))), detailList));
            details = react.createElement(react.Fragment, {}, ...allDetails_1);
            return column(ofArray([new Option(0, [new Screen(0, []), props_2.ColumnSize]), new Option(4, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))]), new Option(3, [ofArray([new DOMAttr(40, [(_arg_5) => {
                dispatch(new Types_Msg(1, [props_2.RealName]));
            }]), ["style", {
                fontFamily: "Open Sans",
                cursor: "pointer",
            }]])])]), singleton(box$0027(singleton(new Common_GenericOption(2, [ofArray([new Modifier_IModifier(0, [props_2.Color[0]]), new Modifier_IModifier(1, [props_2.Color[1]])])])), singleton(content(singleton(new Option_5(3, [singleton(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(4, [])]))])), ofArray([(props_10 = [["style", {
                fontSize: "0.85rem",
            }]], react.createElement("p", keyValueList(props_10, 1), props_2.Name)), details]))))));
        }, undefined, undefined, undefined, "root", "/Users/royalefougard/workspace/iotpool-front3/src/Page/Status.fs", 830);
        const color = (s_7) => {
            let x_1, x_2, x_3;
            const now = now_3();
            let ds;
            const matchValue_3 = s_7.Date;
            if (matchValue_3 == null) {
                ds = s_7.LastCall;
            }
            else {
                const d_3 = subtract(now, matchValue_3);
                ds = (hours(d_3) + (days(d_3) * 24));
            }
            if (ds < 0) {
                return [[new Color_IColor(2, []), new Color_IColor(0, [])], "⚠️En Attente...", true];
            }
            else if ((x_1 = (ds | 0), (x_1 >= 0) && (x_1 < 3))) {
                return [[new Color_IColor(5, []), new Color_IColor(0, [])], "✅OK", false];
            }
            else if ((x_2 = (ds | 0), (x_2 >= 3) && (x_2 < 48))) {
                return [[new Color_IColor(7, []), new Color_IColor(0, [])], "❓Signal interrompu (<48h)", true];
            }
            else if ((x_3 = (ds | 0), (x_3 >= 48) && (x_3 < 148))) {
                return [[new Color_IColor(8, []), new Color_IColor(0, [])], "❕Signal Perdu (+48h)", true];
            }
            else if (ds >= 148) {
                return [[new Color_IColor(0, []), new Color_IColor(8, [])], "❌Eteint/HS (>7J)", true];
            }
            else if (s_7.LastCall < 0) {
                return [[new Color_IColor(2, []), new Color_IColor(0, [])], "En Attente du client", true];
            }
            else {
                return [[new Color_IColor(7, []), new Color_IColor(0, [])], "Signal interrompu", true];
            }
        };
        const named = map_2((tupledArg_3) => {
            const key_1 = tupledArg_3[0] | 0;
            const group = tupledArg_3[1];
            const boxes = toList(map_2((s_10) => {
                let matchValue_9, matchValue_10, t_1;
                const patternInput = color(s_10);
                return box({
                    Color: patternInput[0],
                    ColumnSize: new ISize(6, []),
                    Date: unwrap((matchValue_9 = s_10.Date, (matchValue_9 == null) ? undefined : localFormat(french, "dd/MM/yy HH:mm", toLocalTime(matchValue_9)))),
                    Details: ofArray([s_10.Name, replace(Types_SensorKind_get_toLabel()(s_10.SensorKind), "MG-", "")]),
                    IsOut: patternInput[2],
                    Name: (matchValue_10 = s_10.Title, (matchValue_10 == null) ? s_10.Name : ((t_1 = matchValue_10, toText(printf("%s"))(t_1)))),
                    RealName: s_10.Name,
                    TagMessage: unwrap(patternInput[1]),
                });
            }, group));
            const title_1 = (key_1 === 0) ? "Capteurs à démarrer" : ((key_1 === 1) ? "Capteurs actifs" : ((key_1 === 2) ? "Signal interrompu" : ((key_1 === 3) ? "Signal perdu" : ((key_1 === 4) ? "Capteurs Eteint/HS" : "Autres"))));
            const count = length(group) | 0;
            const title_2 = toText(printf("%s (%i)"))(title_1)(count);
            const color_1 = (key_1 === 0) ? (new Color_IColor(2, [])) : ((key_1 === 1) ? (new Color_IColor(5, [])) : ((key_1 === 2) ? (new Color_IColor(7, [])) : ((key_1 === 3) ? (new Color_IColor(8, [])) : ((key_1 === 4) ? (new Color_IColor(1, [])) : (new Color_IColor(2, []))))));
            const titleColor = (key_1 === 0) ? (new Color_IColor(1, [])) : ((key_1 === 1) ? (new Color_IColor(1, [])) : ((key_1 === 2) ? (new Color_IColor(1, [])) : ((key_1 === 3) ? (new Color_IColor(1, [])) : ((key_1 === 4) ? (new Color_IColor(2, [])) : (new Color_IColor(1, []))))));
            const props_12 = [["style", {
                marginTop: "1rem",
            }]];
            const children_10 = [box$0027(singleton(new Common_GenericOption(2, [ofArray([new Modifier_IModifier(0, [color_1]), new Modifier_IModifier(1, [new Color_IColor(3, [])])])])), singleton(h4(singleton(new Option_6(10, [singleton(new Modifier_IModifier(1, [titleColor]))])))(singleton(title_2)))), columns(ofArray([new Option_7(2, []), new Option_7(9, [singleton(["style", {}])])]), boxes)];
            return react.createElement("div", keyValueList(props_12, 1), ...children_10);
        }, sortBy((tupledArg_2) => tupledArg_2[0], List_groupBy((s_9) => {
            let x_13, x_14, x_15;
            const now_1 = now_3();
            let ds_1;
            const matchValue_8 = s_9.Date;
            if (matchValue_8 == null) {
                ds_1 = s_9.LastCall;
            }
            else {
                const d_4 = subtract(now_1, matchValue_8);
                ds_1 = (hours(d_4) + (days(d_4) * 24));
            }
            if (ds_1 < 0) {
                return 0;
            }
            else if ((x_13 = (ds_1 | 0), (x_13 >= 0) && (x_13 < 3))) {
                return 1;
            }
            else if ((x_14 = (ds_1 | 0), (x_14 >= 3) && (x_14 < 48))) {
                return 2;
            }
            else if ((x_15 = (ds_1 | 0), (x_15 >= 48) && (x_15 < 148))) {
                return 3;
            }
            else if (ds_1 >= 148) {
                return 4;
            }
            else {
                return 5;
            }
        }, model.VisibleResources, {
            Equals: (x_22, y) => (x_22 === y),
            GetHashCode: numberHash,
        }), {
            Compare: comparePrimitives,
        }));
        let data;
        const matchValue_12 = model.DisplayMode;
        switch (matchValue_12.tag) {
            case 0: {
                const named_1 = mapIndexed((i, s_20) => {
                    let s_14, children_16, children_22, children_20;
                    const s_12 = s_20;
                    const kind = replace(Types_SensorKind_get_toLabel()(s_12.SensorKind), "MG-", "");
                    let title_3;
                    const matchValue_13 = s_12.Title;
                    if (matchValue_13 == null) {
                        const children_14 = [(s_14 = "Ce capteur n\'a pas encore de nom... Vous pouvez modifier cela dans les paramètres généraux de la fiche du capteur...", s_14)];
                        title_3 = react.createElement("i", {}, ...children_14);
                    }
                    else {
                        const t_2 = matchValue_13;
                        title_3 = react.createElement("strong", {}, t_2);
                    }
                    const toMessage = (s_15, color_5) => message(ofArray([new Option_8(1, [new Size_ISize(0, [])]), new Option_8(0, [color_5])]), singleton(header(empty(), singleton(s_15))));
                    const patternInput_2 = color(s_12);
                    const tagMessage_2 = patternInput_2[1];
                    const color_6 = patternInput_2[0];
                    const status = (tagMessage_2 == null) ? toMessage("ok", color_6[0]) : toMessage(tagMessage_2, color_6[0]);
                    const props_32 = [new DOMAttr(40, [(_arg_7) => {
                        dispatch(new Types_Msg(1, [s_12.Name]));
                    }]), ["style", {
                        cursor: "pointer",
                    }]];
                    const children_30 = [(children_16 = [toMessage(toText(printf("%i"))(i), new Color_IColor(1, []))], react.createElement("td", {}, ...children_16)), (children_22 = [(children_20 = [react.createElement("span", {}, s_12.Name)], react.createElement("strong", {}, ...children_20))], react.createElement("td", {}, ...children_22)), react.createElement("td", {}, title_3), react.createElement("td", {}, kind), react.createElement("td", {}, status)];
                    return react.createElement("tr", keyValueList(props_32, 1), ...children_30);
                }, model.VisibleResources);
                const up = Fa_i(singleton(new Fa_IconOption(11, ["fas fa-caret-up"])), []);
                const down = Fa_i(singleton(new Fa_IconOption(11, ["fas fa-caret-down"])), []);
                const toggleName = (model.ToggleName.tag === 1) ? down : up;
                const toggleNumSerie = (model.ToggleNumSerie.tag === 1) ? down : up;
                const toggleKind = (model.ToggleKind.tag === 1) ? down : up;
                const toggleStatus = (model.ToggleStatus.tag === 1) ? down : up;
                const children_48 = [table(ofArray([new TableOption(0, []), new TableOption(2, []), new TableOption(1, []), new TableOption(4, [])]), ofArray([(props_46 = [["style", {
                    backgroundColor: "rgb(54,54,54)",
                }]], (children_44 = [(props_44 = [["style", {
                    color: "white",
                    fontSize: "1.2rem",
                }]], (children_42 = [(props_34 = [["style", {
                    color: "white",
                }]], react.createElement("th", keyValueList(props_34, 1), "#")), (props_36 = [new DOMAttr(40, [(_arg_8) => {
                    dispatch(new Types_Msg(14, [new Types_ToggleKind(0, [])]));
                }]), ["style", {
                    color: "white",
                }]], (children_34 = [level(empty(), ofArray([left(empty(), singleton("Numéro de série")), right(empty(), singleton(icon(singleton(new Option_9(0, [new Size_ISize(0, [])])), singleton(toggleNumSerie))))]))], react.createElement("th", keyValueList(props_36, 1), ...children_34))), (props_38 = [new DOMAttr(40, [(_arg_9) => {
                    dispatch(new Types_Msg(14, [new Types_ToggleKind(1, [])]));
                }]), ["style", {
                    color: "white",
                }]], (children_36 = [level(empty(), ofArray([left(empty(), singleton("Nom")), right(empty(), singleton(icon(singleton(new Option_9(0, [new Size_ISize(0, [])])), singleton(toggleName))))]))], react.createElement("th", keyValueList(props_38, 1), ...children_36))), (props_40 = [new DOMAttr(40, [(_arg_10) => {
                    dispatch(new Types_Msg(14, [new Types_ToggleKind(2, [])]));
                }]), ["style", {
                    color: "white",
                }]], (children_38 = [level(empty(), ofArray([left(empty(), singleton("Type de capteur")), right(empty(), singleton(icon(singleton(new Option_9(0, [new Size_ISize(0, [])])), singleton(toggleKind))))]))], react.createElement("th", keyValueList(props_40, 1), ...children_38))), (props_42 = [new DOMAttr(40, [(_arg_11) => {
                    dispatch(new Types_Msg(14, [new Types_ToggleKind(3, [])]));
                }]), ["style", {
                    color: "white",
                }]], (children_40 = [level(empty(), ofArray([left(empty(), singleton("Etat")), right(empty(), singleton(icon(singleton(new Option_9(0, [new Size_ISize(0, [])])), singleton(toggleStatus))))]))], react.createElement("th", keyValueList(props_42, 1), ...children_40)))], react.createElement("tr", keyValueList(props_44, 1), ...children_42)))], react.createElement("thead", keyValueList(props_46, 1), ...children_44))), react.createElement("tbody", {}, ...named_1)]))];
                data = react.createElement(react.Fragment, {}, ...children_48);
                break;
            }
            case 1: {
                const children_51 = [react.createElement("div", {}, ...named)];
                data = react.createElement(react.Fragment, {}, ...children_51);
                break;
            }
            default:
                throw new Error("Match failure: Page.Status.Types.DisplayMode");
        }
        let instructions;
        const matchValue_18 = model.DisplayMode;
        switch (matchValue_18.tag) {
            case 1: {
                instructions = "> Appuyez sur un capteur pour accéder à ses données.";
                break;
            }
            case 0: {
                instructions = "> Cliquez sur une ligne pour accéder aux données d\'un capteur.";
                break;
            }
            default:
                throw new Error("Match failure: Page.Status.Types.DisplayMode");
        }
        let tags_2;
        const matchValue_19 = model.Tags;
        if (matchValue_19 != null) {
            const tags_1 = mapIndexed((i_1, x_24) => tag_46(singleton(new Option_4(1, [item(i_1, ofArray([new Color_IColor(3, []), new Color_IColor(5, []), new Color_IColor(7, []), new Color_IColor(8, []), new Color_IColor(1, [])]))])), singleton(x_24.Label)), matchValue_19);
            const children_55 = [react.createElement("div", {
                className: "block",
            }, ...tags_1)];
            tags_2 = react.createElement("div", {}, ...children_55);
        }
        else {
            tags_2 = react.createElement(react.Fragment, {});
        }
        let filtertags;
        const matchValue_20 = model.FilterTags;
        if (matchValue_20 != null) {
            const tags_4 = toList(map((taglist) => columns(empty(), toList(taglist)), Seq_split(6, map_2((tag_25) => {
                let matchValue_21, ctag;
                return column(singleton(new Option(0, [new Screen(0, []), new ISize(6, [])])), singleton(left(empty(), singleton(list_33(singleton(new List_Option(0, [])), ofArray([tag_46(ofArray([new Option_4(1, [(matchValue_21 = model.CurrentTag, (matchValue_21 != null) ? ((ctag = matchValue_21, (ctag.indexOf(tag_25) >= 0) ? (new Color_IColor(7, [])) : (new Color_IColor(3, [])))) : (new Color_IColor(3, [])))]), new Option_4(0, [new Size_ISize(0, [])]), new Option_4(3, []), new Option_4(4, [ofArray([new DOMAttr(40, [(_arg_12) => {
                    dispatch(new Types_Msg(3, [tag_25]));
                }]), ["style", {
                    cursor: "pointer",
                }]])])]), singleton(react.createElement("strong", {}, tag_25))), tag_46(singleton(new Option_4(1, [new Color_IColor(4, [])])), singleton(icon(empty(), singleton(react.createElement("i", {
                    className: "fas fa-question",
                })))))]))))));
            }, matchValue_20))));
            filtertags = box$0027(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(0, [new Color_IColor(1, [])]))])), ofArray([level(empty(), ofArray([left(empty(), singleton(h4(singleton(new Option_6(9, [ofArray([["style", {
                color: "#66C88E",
                cursor: "pointer",
            }], new DOMAttr(40, [(_arg_13) => {
                dispatch(new Types_Msg(8, []));
            }])])])))(singleton("Tri par mots clés")))), right(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(1, [new Color_IColor(3, [])]))])), singleton(switch$(ofArray([new Option_3(6, [model.DisplayTags]), new Option_3(0, [new Color_IColor(4, [])]), new Option_3(2, []), new Option_3(12, ["tagsSwitch"]), new Option_3(9, [singleton(new DOMAttr(9, [(_arg_14) => {
                dispatch(new Types_Msg(8, []));
            }]))])]), singleton(model.DisplayTags ? "Appuyez pour cacher" : "Appuyez pour afficher"))))])), model.DisplayTags ? div(empty(), ofArray([label_1(singleton(new Option_1(4, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])), singleton("Appuyez sur un mot clé pour afficher vos capteurs groupés par mot clé")), react.createElement("div", {}, ...tags_4)])) : react.createElement(react.Fragment, {})]));
        }
        else {
            filtertags = react.createElement(react.Fragment, {});
        }
        let downloadBox;
        const now_2 = now_3();
        let dropdownMonth;
        const ddItems = mapIndexed((i_2, x_25) => react.createElement("option", {
            value: i_2,
        }, x_25), take(month_1(now_2), ofArray(["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"])));
        const props_73 = [["style", {
            backgroundColor: "white",
            height: "2.5rem",
            padding: "0.2rem",
            borderRadius: "0.25rem",
        }], new DOMAttr(9, [(e) => {
            dispatch(new Types_Msg(10, [parse(Browser_Types_Event__Event_get_Value(e), 511, false, 32) + 1]));
        }]), new HTMLAttr(1, [""])];
        const children_71 = [(props_71 = [["style", {
            fontSize: "0.7rem",
        }]], (children_69 = append(singleton(react.createElement("option", {
            value: -1,
        }, "Télécharger les rapports PDF mensuel des capteurs affichés ")), ddItems), react.createElement("optgroup", keyValueList(props_71, 1), ...children_69)))];
        dropdownMonth = react.createElement("select", keyValueList(props_73, 1), ...children_71);
        let dropdownWeek;
        const ddItems_1 = map_2((x_27) => {
            const children_73 = [toText(printf("semaine %i"))(x_27)];
            return react.createElement("option", {
                value: x_27,
            }, ...children_73);
        }, toList(rangeDouble(1, 1, sum((source_6 = toList(rangeDouble(1, 1, 12)), map((year = (year_1(now_2) | 0), (month) => getWeekFromMondaytoMonday(year, month)), source_6)), {
            GetZero: () => 0,
            Add: (x_26, y_2) => (x_26 + y_2),
        }))));
        const props_81 = [["style", {
            backgroundColor: "white",
            height: "2.5rem",
            borderRadius: "0.25rem",
            padding: "0.2rem",
        }], new DOMAttr(9, [(e_1) => {
            dispatch(new Types_Msg(13, [parse(Browser_Types_Event__Event_get_Value(e_1), 511, false, 32)]));
        }]), new HTMLAttr(1, [""])];
        const children_79 = [(props_79 = [["style", {
            fontSize: "0.7rem",
        }]], (children_77 = append(singleton(react.createElement("option", {
            value: -1,
        }, "Télécharger les rapports PDF hebdomadaire des capteurs affichés ")), ddItems_1), react.createElement("optgroup", keyValueList(props_79, 1), ...children_77)))];
        dropdownWeek = react.createElement("select", keyValueList(props_81, 1), ...children_79);
        downloadBox = columns(empty(), ofArray([column(empty(), singleton(dropdownMonth)), column(empty(), singleton(dropdownWeek))]));
        const sensors = toArray(map_2((s_36) => {
            let matchValue_22, t_3;
            return {
                label: (matchValue_22 = s_36.Title, (matchValue_22 == null) ? s_36.Name : ((t_3 = matchValue_22, toText(printf("%s (%s)"))(t_3)(s_36.Name)))),
                value: s_36.Name,
            };
        }, model.Data));
        const kindsChoice = toArray(sortBy((x_30) => x_30.value, List_distinctBy((x_28) => x_28.value, map_2((s_37) => ({
            label: Types_SensorKind_get_toLabel()(s_37.SensorKind),
            value: toString(s_37.SensorKind),
        }), model.Data), {
            Equals: (x_29, y_3) => (x_29 === y_3),
            GetHashCode: stringHash,
        }), {
            Compare: comparePrimitives,
        }));
        let tagsChoice;
        const matchValue_23 = model.FilterTags;
        tagsChoice = ((matchValue_23 != null) ? toArray(map_2((tag_37) => ({
            label: tag_37,
            value: tag_37,
        }), matchValue_23)) : []);
        const tableMode_1 = equals(model.DisplayMode, new Types_DisplayMode(0, []));
        let switchLabel_1;
        if (equals(model.DisplayMode, new Types_DisplayMode(0, []))) {
            const children_83 = ["Affichage : Blocs ou ", (props_83 = [["style", {
                color: "#66C88E",
            }]], react.createElement("strong", keyValueList(props_83, 1), "Tableau"))];
            switchLabel_1 = react.createElement("span", {}, ...children_83);
        }
        else {
            const children_87 = ["Affichage : ", (props_87 = [["style", {
                color: "#66C88E",
            }]], react.createElement("strong", keyValueList(props_87, 1), "Blocs")), " ou Tableau"];
            switchLabel_1 = react.createElement("span", {}, ...children_87);
        }
        const children_100 = [box$0027(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(0, [new Color_IColor(2, [])]))])), ofArray([level(empty(), ofArray([left(empty(), singleton(h4(empty())(singleton("Mes capteurs")))), right(empty(), singleton(item_1(empty(), singleton(switch$(ofArray([new Option_3(6, [tableMode_1]), new Option_3(0, [new Color_IColor(4, [])]), new Option_3(2, []), new Option_3(12, ["tableSwitch"]), new Option_3(9, [singleton(new DOMAttr(9, [(_arg_15) => {
            dispatch(new Types_Msg(4, []));
        }]))])]), singleton(switchLabel_1))))))])), columns(empty(), ofArray([column(empty(), singleton(h6(empty())(singleton("Filtres")))), column(empty(), singleton(level(empty(), singleton(left(empty(), ofArray([item_1(empty(), singleton("Par nom :")), item_1(empty(), singleton((props_91 = {
            alreadySelected: model.SelectedSensors,
            customLabel: "😶 Aucun capteur sélectionné",
            list: sensors,
            onSelect: (list_25) => {
                toConsole(printf("Selected: %A"))(list_25);
                dispatch(new Types_Msg(15, [list_25]));
            },
        }, react.createElement(MultiSelect, props_91))))])))))), column(empty(), singleton(level(empty(), singleton(left(empty(), ofArray([item_1(empty(), singleton("Par Mot Clé :")), item_1(empty(), singleton((props_93 = {
            alreadySelected: model.SelectedTags,
            customLabel: "😶 Aucun mot clé sélectionné",
            list: tagsChoice,
            onSelect: (list_26) => {
                toConsole(printf("Selected: %A"))(list_26);
                dispatch(new Types_Msg(16, [list_26]));
            },
        }, react.createElement(MultiSelect, props_93))))])))))), column(empty(), singleton(level(empty(), singleton(left(empty(), ofArray([item_1(empty(), singleton("Par Type :")), item_1(empty(), singleton((props_95 = {
            alreadySelected: model.SelectedKinds,
            customLabel: "😶 Aucun type sélectionné",
            list: kindsChoice,
            onSelect: (list_27) => {
                toConsole(printf("Selected: %A"))(list_27);
                dispatch(new Types_Msg(17, [list_27]));
            },
        }, react.createElement(MultiSelect, props_95))))]))))))]))])), box$0027(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(0, [new Color_IColor(2, [])]))])), singleton(level(empty(), ofArray([left(empty(), ofArray([item_1(empty(), singleton(h6(empty())(singleton("Téléchargements")))), item_1(empty(), singleton((children_94 = [react.createElement("i", {}, "(capteurs sélectionnés)")], react.createElement("span", {}, ...children_94))))])), right(empty(), ofArray([item_1(empty(), singleton(label_1(empty(), singleton("Date de début")))), item_1(empty(), singleton(input(ofArray([new Option_2(1, [new IInputType(3, [])]), new Option_2(2, [new Color_IColor(4, [])]), new Option_2(15, [ofArray([new HTMLAttr(161, [model.GraphStartDate]), new DOMAttr(9, [(e_2) => {
            dispatch(new Types_Msg(6, [Browser_Types_Event__Event_get_Value(e_2)]));
        }])])])])))), item_1(empty(), singleton(label_1(empty(), singleton("Date de fin")))), item_1(empty(), singleton(input(ofArray([new Option_2(1, [new IInputType(3, [])]), new Option_2(2, [new Color_IColor(4, [])]), new Option_2(15, [ofArray([new HTMLAttr(161, [model.GraphEndDate]), new DOMAttr(9, [(e_3) => {
            dispatch(new Types_Msg(7, [Browser_Types_Event__Event_get_Value(e_3)]));
        }])])])])))), item_1(empty(), singleton(button(ofArray([new Option_10(0, [new Color_IColor(4, [])]), new Option_10(4, []), new Option_10(17, [singleton(new DOMAttr(40, [(_arg_16) => {
            dispatch(new Types_Msg(18, [new Types_ReportKind(1, []), model.GraphStartDate, model.GraphEndDate]));
        }]))])]), ofArray([icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-file-pdf"])), []))), react.createElement("span", {}, "PDF")])))), item_1(empty(), singleton(button(ofArray([new Option_10(0, [new Color_IColor(4, [])]), new Option_10(4, []), new Option_10(17, [singleton(new DOMAttr(40, [(_arg_17) => {
            dispatch(new Types_Msg(18, [new Types_ReportKind(0, []), model.GraphStartDate, model.GraphEndDate]));
        }]))])]), ofArray([icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-file-csv"])), []))), react.createElement("span", {}, "CSV")]))))]))])))), data];
        return react.createElement(react.Fragment, {}, ...children_100);
    }
}

