import { FSharpRef, Record } from "../fable-library-js.4.24.0/Types.js";
import { record_type, class_type, list_type, string_type } from "../fable-library-js.4.24.0/Reflection.js";
import { ofArray, collect, map as map_1, singleton, tail, cons, empty, head, isEmpty } from "../fable-library-js.4.24.0/List.js";
import { FSharpResult$2 } from "../fable-library-js.4.24.0/Result.js";
import { tryParse } from "../fable-library-js.4.24.0/Int32.js";
import { empty as empty_1, ofSeq, tryFind } from "../fable-library-js.4.24.0/Map.js";
import { some, bind } from "../fable-library-js.4.24.0/Option.js";
import { substring, split } from "../fable-library-js.4.24.0/String.js";
import { item, equalsWith } from "../fable-library-js.4.24.0/Array.js";
import { comparePrimitives, defaultOf } from "../fable-library-js.4.24.0/Util.js";
import { ofFunc, tuple } from "./prelude.fs.js";
import { map as map_2, choose } from "../fable-library-js.4.24.0/Seq.js";

export class State$1 extends Record {
    constructor(visited, unvisited, args, value) {
        super();
        this.visited = visited;
        this.unvisited = unvisited;
        this.args = args;
        this.value = value;
    }
}

export function State$1_$reflection(gen0) {
    return record_type("Elmish.UrlParser.State`1", [gen0], State$1, () => [["visited", list_type(string_type)], ["unvisited", list_type(string_type)], ["args", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["value", gen0]]);
}

export function StateModule_mkState(visited, unvisited, args, value) {
    return new State$1(visited, unvisited, args, value);
}

export function StateModule_map(f, _arg) {
    return new State$1(_arg.visited, _arg.unvisited, _arg.args, f(_arg.value));
}

export function custom(tipe, stringToSomething) {
    return (_arg) => {
        const unvisited = _arg.unvisited;
        if (!isEmpty(unvisited)) {
            const next = head(unvisited);
            const matchValue = stringToSomething(next);
            if (matchValue.tag === 1) {
                return empty();
            }
            else {
                return singleton(StateModule_mkState(cons(next, _arg.visited), tail(unvisited), _arg.args, _arg.value(matchValue.fields[0])));
            }
        }
        else {
            return empty();
        }
    };
}

export function str(state) {
    return custom("string", (ResultValue) => (new FSharpResult$2(0, [ResultValue])))(state);
}

export function i32(state) {
    return custom("i32", (arg_1) => {
        let _arg;
        let outArg = 0;
        _arg = [tryParse(arg_1, 511, false, 32, new FSharpRef(() => outArg, (v) => {
            outArg = (v | 0);
        })), outArg];
        return _arg[0] ? (new FSharpResult$2(0, [_arg[1]])) : (new FSharpResult$2(1, ["Can\'t parse int"]));
    })(state);
}

export function s(str_1) {
    return (_arg) => {
        const unvisited = _arg.unvisited;
        if (!isEmpty(unvisited)) {
            const next = head(unvisited);
            if (next === str_1) {
                return singleton(StateModule_mkState(cons(next, _arg.visited), tail(unvisited), _arg.args, _arg.value));
            }
            else {
                return empty();
            }
        }
        else {
            return empty();
        }
    };
}

export function map(subValue, parse_1) {
    return (_arg) => map_1((arg10$0040) => StateModule_map(_arg.value, arg10$0040), parse_1(new State$1(_arg.visited, _arg.unvisited, _arg.args, subValue)));
}

export function oneOf(parsers, state) {
    return collect((parser) => parser(state), parsers);
}

export function top(state) {
    return singleton(state);
}

export function customParam(key, func) {
    return (_arg) => {
        const args = _arg.args;
        return singleton(StateModule_mkState(_arg.visited, _arg.unvisited, args, _arg.value(func(tryFind(key, args)))));
    };
}

export function stringParam(name) {
    return customParam(name, (x) => x);
}

export const intParamHelp = (option) => bind((value) => {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(value, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return undefined;
    }
}, option);

export function intParam(name) {
    return customParam(name, intParamHelp);
}

export function parseHelp(states_mut) {
    parseHelp:
    while (true) {
        const states = states_mut;
        if (!isEmpty(states)) {
            const state = head(states);
            const matchValue = state.unvisited;
            let matchResult;
            if (!isEmpty(matchValue)) {
                if (head(matchValue) === "") {
                    if (isEmpty(tail(matchValue))) {
                        matchResult = 1;
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return some(state.value);
                case 1:
                    return some(state.value);
                default: {
                    states_mut = tail(states);
                    continue parseHelp;
                }
            }
        }
        else {
            return undefined;
        }
        break;
    }
}

export function splitUrl(url) {
    const matchValue = ofArray(url.split("/"));
    let matchResult, segments, segments_1;
    if (!isEmpty(matchValue)) {
        if (head(matchValue) === "") {
            matchResult = 0;
            segments = tail(matchValue);
        }
        else {
            matchResult = 1;
            segments_1 = matchValue;
        }
    }
    else {
        matchResult = 1;
        segments_1 = matchValue;
    }
    switch (matchResult) {
        case 0:
            return segments;
        default:
            return segments_1;
    }
}

/**
 * parse a given part of the location
 */
export function parse(parser, url, args) {
    return parseHelp(parser(new State$1(empty(), splitUrl(url), args, (x) => x)));
}

export function toKeyValuePair(segment) {
    const matchValue = split(segment, ["="], undefined, 0);
    if (!equalsWith((x, y) => (x === y), matchValue, defaultOf()) && (matchValue.length === 2)) {
        const value = item(1, matchValue);
        return tuple(ofFunc(decodeURIComponent, item(0, matchValue)), ofFunc(decodeURIComponent, value));
    }
    else {
        return undefined;
    }
}

export function parseParams(querystring) {
    if (querystring.length > 1) {
        return ofSeq(choose((x) => x, map_2(toKeyValuePair, split(substring(querystring, 1), ["&"], undefined, 0))), {
            Compare: comparePrimitives,
        });
    }
    else {
        return empty_1({
            Compare: comparePrimitives,
        });
    }
}

export function parsePath(parser, location) {
    return parse(parser, location.pathname, parseParams(location.search));
}

export function parseHash(parser, location) {
    let patternInput;
    const hash = (location.hash.length > 1) ? substring(location.hash, 1) : "";
    if (hash.indexOf("?") >= 0) {
        const h = substring(hash, 0, hash.indexOf("?"));
        patternInput = [h, substring(hash, h.length)];
    }
    else {
        patternInput = [hash, "?"];
    }
    return parse(parser, patternInput[0], parseParams(patternInput[1]));
}

