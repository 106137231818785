import { Record, Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, tuple_type, bool_type, option_type, list_type, string_type, union_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Sensor_Status_$reflection } from "../Shared.fs.js";
import { Props_load } from "../SessionStorage.fs.js";
import { mapIndexed, isEmpty, map, length, singleton, filter as filter_1, append, ofArray, empty } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { isMatch, escape, create, split } from "../fable_modules/fable-library-js.4.24.0/RegExp.js";
import { uncurry2, equals } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { printf, toText, replace, join } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { toList, head, map as map_1 } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { Types_SensorKind_get_toLabel } from "../../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Inside_Str, Inside_Heading_h1, Inside_Section } from "../GogoFulma.fs.js";
import { Option as Option_5, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Size_ISize, Common_GenericOption, TextAlignment_Option, TextSize_Option, Modifier_IModifier, Color_IColor, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_1, label as label_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Option as Option_2, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { FunctionComponent_Of_60E46241 } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { Option as Option_3, tag as tag_22 } from "../fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import { Option as Option_4, content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.24.0/MapUtil.js";
import { toLocalTime, subtract, now as now_1 } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { days, hours } from "../fable_modules/fable-library-js.4.24.0/TimeSpan.js";
import { localFormat } from "../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { unwrap, value } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { h5, Option as Option_7, h4, h6, h2 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { header, Option as Option_6, message } from "../fable_modules/Fulma.2.16.0/Components/Message.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { right, left, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";

export class Types_Filter extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Label"];
    }
}

export function Types_Filter_$reflection() {
    return union_type("Page.Tags.Types.Filter", [], Types_Filter, () => [[]]);
}

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Load", "GetSensor", "SearchTextUpdated"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Tags.Types.Msg", [], Types_Msg, () => [[], [["Item", string_type]], [["Item1", Types_Filter_$reflection()], ["Item2", string_type]]]);
}

export class Types_DisplayMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Table", "Tiles"];
    }
}

export function Types_DisplayMode_$reflection() {
    return union_type("Page.Tags.Types.DisplayMode", [], Types_DisplayMode, () => [[], []]);
}

export class Types_Model extends Record {
    constructor(Data, VisibleResources, Error$, AdminView, CurrentSearches, DisplayMode) {
        super();
        this.Data = Data;
        this.VisibleResources = VisibleResources;
        this.Error = Error$;
        this.AdminView = AdminView;
        this.CurrentSearches = CurrentSearches;
        this.DisplayMode = DisplayMode;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Tags.Types.Model", [], Types_Model, () => [["Data", list_type(Sensor_Status_$reflection())], ["VisibleResources", list_type(Sensor_Status_$reflection())], ["Error", option_type(string_type)], ["AdminView", bool_type], ["CurrentSearches", list_type(tuple_type(Types_Filter_$reflection(), string_type, list_type(string_type)))], ["DisplayMode", Types_DisplayMode_$reflection()]]);
}

export function Types_initialModel(list, user) {
    let matchValue_1;
    let displayMode;
    try {
        displayMode = (Props_load().StatusTableMode ? (new Types_DisplayMode(0, [])) : (new Types_DisplayMode(1, [])));
    }
    catch (matchValue) {
        displayMode = (new Types_DisplayMode(1, []));
    }
    return new Types_Model(list, list, undefined, (user == null) ? false : ((matchValue_1 = user.UserKind, (matchValue_1.tag === 1) ? true : (matchValue_1.tag === 0))), empty(), displayMode);
}

export function State_update(msg, model) {
    if (msg.tag === 2) {
        const text = msg.fields[1];
        const kind = msg.fields[0];
        const terms_1 = ofArray(split(text, "(?:\\s*,\\s*)|\\s+"));
        const updatedSearches = append(filter_1((tupledArg_1) => !equals(tupledArg_1[0], kind), model.CurrentSearches), singleton([kind, text, terms_1]));
        let model_2;
        const searches = updatedSearches;
        const model_1 = model;
        model_2 = (new Types_Model(model_1.Data, filter_1((r) => (length(filter_1((ok_3) => ok_3, map((tupledArg) => {
            let matchValue_1;
            const matchValue = tupledArg[1].trim();
            if (matchValue.length <= 0) {
                return true;
            }
            else {
                const regex = create(join("", map_1((x) => (("(?=.*" + escape(x)) + ")"), tupledArg[2])), 1);
                if (((matchValue_1 = r.Title, (matchValue_1 == null) ? false : isMatch(regex, matchValue_1))) ? true : isMatch(regex, r.Name)) {
                    return true;
                }
                else {
                    return isMatch(regex, replace(Types_SensorKind_get_toLabel()(r.SensorKind), "-", ""));
                }
            }
        }, searches))) === length(searches)), model_1.Data), model_1.Error, model_1.AdminView, model_1.CurrentSearches, model_1.DisplayMode));
        return [new Types_Model(model_2.Data, model_2.VisibleResources, model_2.Error, model_2.AdminView, updatedSearches, model_2.DisplayMode), Cmd_none()];
    }
    else {
        return [model, Cmd_none()];
    }
}

export function View_root(model, dispatch) {
    let filter, label, currentSearchValue, found, s_14, props_38, children_37, props_36, children_35, props_34;
    if (isEmpty(model.Data)) {
        return Inside_Section(singleton(Inside_Heading_h1(singleton(Inside_Str("Aucun capteur n\'est pour l\'instant référencé sur votre compte.")))));
    }
    else {
        let filters;
        const tableMode = equals(model.DisplayMode, new Types_DisplayMode(0, []));
        const switchLabel = equals(model.DisplayMode, new Types_DisplayMode(0, [])) ? "Mode Tableau" : "Mode Blocs";
        filters = columns(empty(), singleton(column(singleton(new Option(0, [new Screen(0, []), new ISize(3, [])])), singleton((filter = (new Types_Filter()), (label = "Filtrez ou recherchez vos capteurs en tapant un *numéro de série*, un *type* ou les premières lettres du *nom* de votre capteur", (currentSearchValue = ((found = filter_1((tupledArg) => equals(filter, tupledArg[0]), model.CurrentSearches), isEmpty(found) ? "" : head(found)[1])), div(empty(), ofArray([label_1(singleton(new Option_1(4, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])), singleton(label)), div_1(empty(), singleton(columns(empty(), singleton(column(empty(), singleton(input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(12, ["Ex: 377BF0 ou CO2 ou Clinique de XXX, etc..."]), new Option_2(8, [currentSearchValue]), new Option_2(13, [(ev) => {
            dispatch(new Types_Msg(2, [filter, Browser_Types_Event__Event_get_Value(ev)]));
        }])]))))))))])))))))));
        const box = FunctionComponent_Of_60E46241((props) => {
            let children_5, props_10;
            let details;
            let tag;
            const matchValue_1 = props.TagMessage;
            tag = ((matchValue_1 == null) ? react.createElement(react.Fragment, {}) : tag_22(singleton(new Option_3(1, [props.Color[1]])), singleton(matchValue_1)));
            if (model.AdminView) {
                details = content(singleton(new Option_4(3, [singleton(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(6, [])]))])), singleton((children_5 = [tag_22(singleton(new Option_3(1, [new Color_IColor(2, [])])), singleton(props.Details)), react.createElement("span", {}, " "), tag_22(singleton(new Option_3(1, [new Color_IColor(2, [])])), singleton(props.Date)), react.createElement("span", {}, " "), tag], react.createElement(react.Fragment, {}, ...children_5))));
            }
            else {
                const children_8 = [tag_22(singleton(new Option_3(1, [new Color_IColor(2, [])])), singleton(props.Details)), react.createElement("span", {}, " "), tag];
                details = react.createElement(react.Fragment, {}, ...children_8);
            }
            return column(ofArray([new Option(0, [new Screen(0, []), props.ColumnSize]), new Option(4, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))]), new Option(3, [ofArray([new DOMAttr(40, [(_arg_4) => {
                dispatch(new Types_Msg(1, [props.RealName]));
            }]), ["style", {
                fontFamily: "Open Sans",
                cursor: "pointer",
            }]])])]), singleton(box$0027(singleton(new Common_GenericOption(2, [ofArray([new Modifier_IModifier(0, [props.Color[0]]), new Modifier_IModifier(1, [props.Color[1]])])])), singleton(content(singleton(new Option_4(3, [singleton(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(4, [])]))])), ofArray([(props_10 = [["style", {
                fontSize: "1rem",
            }]], react.createElement("p", keyValueList(props_10, 1), props.Name)), details]))))));
        }, undefined, undefined, undefined, "root", "/Users/royalefougard/workspace/iotpool-front3/src/Page/Tags.fs", 188);
        const color = (s_9) => {
            let x, x_1, x_2;
            const now = now_1();
            let ds;
            const matchValue_3 = s_9.Date;
            if (matchValue_3 == null) {
                ds = s_9.LastCall;
            }
            else {
                const d = subtract(now, matchValue_3);
                ds = (hours(d) + (days(d) * 24));
            }
            if ((x = (ds | 0), (x >= 0) && (x < 2))) {
                return [[new Color_IColor(4, []), new Color_IColor(0, [])], undefined];
            }
            else if ((x_1 = (ds | 0), (x_1 >= 2) && (x_1 < 24))) {
                if (s_9.SensorKind.tag === 14) {
                    return [[new Color_IColor(4, []), new Color_IColor(0, [])], undefined];
                }
                else {
                    return [[new Color_IColor(7, []), new Color_IColor(0, [])], "En attente..."];
                }
            }
            else if ((x_2 = (ds | 0), (x_2 >= 24) && (x_2 < 48))) {
                if (s_9.SensorKind.tag === 14) {
                    return [[new Color_IColor(7, []), new Color_IColor(0, [])], "En attente..."];
                }
                else {
                    return [[new Color_IColor(8, []), new Color_IColor(0, [])], "Pas de signal"];
                }
            }
            else if (s_9.LastCall < 0) {
                return [[new Color_IColor(2, []), new Color_IColor(0, [])], "Pas activé"];
            }
            else {
                return [[new Color_IColor(8, []), new Color_IColor(0, [])], undefined];
            }
        };
        const named = columns(singleton(new Option_5(2, [])), toList(map((s_10) => {
            let matchValue_7, txt_1, arg_2;
            const patternInput = color(s_10);
            return box({
                Color: patternInput[0],
                ColumnSize: new ISize(7, []),
                Date: (matchValue_7 = s_10.Date, (matchValue_7 == null) ? "" : localFormat(french, "(dd/MM HH:mm)", toLocalTime(matchValue_7))),
                Details: (txt_1 = replace(Types_SensorKind_get_toLabel()(s_10.SensorKind), "MG-", ""), toText(printf("%s / %s"))(s_10.Name)(txt_1)),
                Name: (arg_2 = value(s_10.Title), toText(printf("%s"))(arg_2)),
                RealName: s_10.Name,
                TagMessage: unwrap(patternInput[1]),
            });
        }, filter_1((q) => (q.Title != null), model.VisibleResources))));
        let unNamed;
        const list_7 = filter_1((q_1) => (q_1.Title == null), model.VisibleResources);
        if (isEmpty(list_7)) {
            unNamed = "";
        }
        else {
            const children_11 = [h2(empty())(singleton("Capteurs non triés.")), h6(empty())(singleton((s_14 = "Astuce : ces capteurs n\'ont pas encore de nom. Vous pouvez changer cela depuis les paramètres généraux de chaque capteur.", s_14))), columns(singleton(new Option_5(2, [])), toList(map((s_11) => {
                let matchValue_8;
                const patternInput_1 = color(s_11);
                return box({
                    Color: patternInput_1[0],
                    ColumnSize: new ISize(5, []),
                    Date: (matchValue_8 = s_11.Date, (matchValue_8 == null) ? "" : localFormat(french, "(dd/MM HH:mm)", toLocalTime(matchValue_8))),
                    Details: replace(Types_SensorKind_get_toLabel()(s_11.SensorKind), "MG-", ""),
                    Name: s_11.Name,
                    RealName: s_11.Name,
                    TagMessage: unwrap(patternInput_1[1]),
                });
            }, list_7)))];
            unNamed = react.createElement("div", {}, ...children_11);
        }
        let data;
        if (model.DisplayMode.tag === 1) {
            const children_44 = [react.createElement("div", {}, named), react.createElement("hr", {}), unNamed];
            data = react.createElement(react.Fragment, {}, ...children_44);
        }
        else {
            const named_1 = mapIndexed(uncurry2((index) => FunctionComponent_Of_60E46241((s_15) => {
                let s_17, children_17, children_23, children_21;
                const kind = replace(Types_SensorKind_get_toLabel()(s_15.SensorKind), "MG-", "");
                let title;
                const matchValue_11 = s_15.Title;
                if (matchValue_11 == null) {
                    const children_15 = [(s_17 = "Ce capteur n\'a pas encore de nom... Vous pouvez modifier cela dans les paramètres généraux de la fiche du capteur...", s_17)];
                    title = react.createElement("i", {}, ...children_15);
                }
                else {
                    const t_1 = matchValue_11;
                    title = react.createElement("strong", {}, t_1);
                }
                const toMessage = (s_18, color_3) => message(ofArray([new Option_6(1, [new Size_ISize(0, [])]), new Option_6(0, [color_3])]), singleton(header(empty(), singleton(s_18))));
                const patternInput_2 = color(s_15);
                const tagMessage_2 = patternInput_2[1];
                const color_4 = patternInput_2[0];
                const status = (tagMessage_2 == null) ? toMessage("ok", color_4[0]) : toMessage(tagMessage_2, color_4[0]);
                const props_32 = [new DOMAttr(40, [(_arg_5) => {
                    dispatch(new Types_Msg(1, [s_15.Name]));
                }]), ["style", {
                    cursor: "pointer",
                }]];
                const children_31 = [(children_17 = [toMessage(toText(printf("%i"))(index), new Color_IColor(1, []))], react.createElement("td", {}, ...children_17)), (children_23 = [(children_21 = [react.createElement("span", {}, s_15.Name)], react.createElement("strong", {}, ...children_21))], react.createElement("td", {}, ...children_23)), react.createElement("td", {}, title), react.createElement("td", {}, kind), react.createElement("td", {}, status)];
                return react.createElement("tr", keyValueList(props_32, 1), ...children_31);
            }, undefined, undefined, undefined, "root", "/Users/royalefougard/workspace/iotpool-front3/src/Page/Tags.fs", 347)), model.VisibleResources);
            const up = Fa_i(singleton(new Fa_IconOption(11, ["fas fa-caret-up"])), []);
            const down = Fa_i(singleton(new Fa_IconOption(11, ["fas fa-caret-down"])), []);
            const children_41 = [table(ofArray([new TableOption(0, []), new TableOption(2, []), new TableOption(1, []), new TableOption(4, [])]), ofArray([(props_38 = [["style", {
                backgroundColor: "rgb(54,54,54)",
            }]], (children_37 = [(props_36 = [["style", {
                color: "white",
                fontSize: "1.2rem",
            }]], (children_35 = [(props_34 = [["style", {
                color: "white",
            }]], react.createElement("th", keyValueList(props_34, 1), "#"))], react.createElement("tr", keyValueList(props_36, 1), ...children_35)))], react.createElement("thead", keyValueList(props_38, 1), ...children_37))), react.createElement("tbody", {}, ...named_1)]))];
            data = react.createElement(react.Fragment, {}, ...children_41);
        }
        const instructions = (model.DisplayMode.tag === 0) ? "> Cliquez sur une ligne pour accéder aux données d\'un capteur." : "> Appuyez sur un capteur pour accéder à ses données.";
        return section(empty(), ofArray([box$0027(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(0, [new Color_IColor(5, [])]))])), ofArray([level(empty(), ofArray([left(empty(), singleton(h4(singleton(new Option_7(9, [singleton(["style", {
            color: "#66C88E",
        }])])))(singleton("Mes groupes de capteurs")))), right(empty(), singleton(h5(singleton(new Option_7(9, [singleton(["style", {
            color: "#66C88E",
        }])])))(singleton(instructions))))])), filters])), data]));
    }
}

