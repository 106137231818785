import { Record } from "./fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, option_type, float64_type, string_type } from "./fable_modules/fable-library-js.4.24.0/Reflection.js";
import { ofArray, head, toArray, length, item, mapIndexed, map } from "./fable_modules/fable-library-js.4.24.0/List.js";
import { localFormat } from "./fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "./fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { toLocalTime } from "./fable_modules/fable-library-js.4.24.0/Date.js";
import { toConsole, printf, toText } from "./fable_modules/fable-library-js.4.24.0/String.js";
import * as react from "react";
import { LineChart, Legend, Tooltip, YAxis, XAxis, CartesianGrid, Line } from "recharts";

export function valueExtractor(_arg) {
    switch (_arg.tag) {
        case 2:
            return _arg.fields[0];
        case 1:
            throw new Error("not handled");
        default:
            return _arg.fields[0];
    }
}

export class SimpleChartData extends Record {
    constructor(date, v1, v2, v3, v4) {
        super();
        this.date = date;
        this.v1 = v1;
        this.v2 = v2;
        this.v3 = v3;
        this.v4 = v4;
    }
}

export function SimpleChartData_$reflection() {
    return record_type("RechartsHelper.SimpleChartData", [], SimpleChartData, () => [["date", string_type], ["v1", option_type(float64_type)], ["v2", option_type(float64_type)], ["v3", option_type(float64_type)], ["v4", option_type(float64_type)]]);
}

export class Payload extends Record {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = value;
    }
}

export function Payload_$reflection() {
    return record_type("RechartsHelper.Payload", [], Payload, () => [["name", string_type], ["value", float64_type]]);
}

export function dataFromSensorValues(values) {
    const extractV1 = (data) => map((value) => {
        const v = valueExtractor(value.Value);
        const date_1 = localFormat(french, "dd/MM HH:mm", toLocalTime(value.Date));
        return new SimpleChartData(toText(printf("%s"))(date_1), v, undefined, undefined, undefined);
    }, data);
    const extractNext = (newValues, which, previousvalues) => mapIndexed((i, value_1) => {
        const v_1 = valueExtractor(value_1.Value);
        const previous = item(i, previousvalues);
        switch (which) {
            case 1:
                return new SimpleChartData(previous.date, v_1, previous.v2, previous.v3, previous.v4);
            case 2:
                return new SimpleChartData(previous.date, previous.v1, v_1, previous.v3, previous.v4);
            case 3:
                return new SimpleChartData(previous.date, previous.v1, previous.v2, v_1, previous.v4);
            default:
                return new SimpleChartData(previous.date, previous.v1, previous.v2, previous.v3, v_1);
        }
    }, newValues);
    const matchValue = length(values) | 0;
    switch (matchValue) {
        case 0:
            return [];
        case 1:
            return toArray(extractV1(head(values).List));
        case 2:
            return toArray(extractNext(item(1, values).List, 2, extractV1(item(0, values).List)));
        case 3:
            return toArray(extractNext(item(2, values).List, 3, extractNext(item(1, values).List, 2, extractV1(item(0, values).List))));
        default:
            return toArray(extractNext(item(3, values).List, 4, extractNext(item(2, values).List, 3, extractNext(item(1, values).List, 2, extractV1(item(0, values).List)))));
    }
}

export function graph(data, kind) {
    let props_4, props_6, props_8, props_10, props_12;
    toConsole(printf("%A dta length"))(data);
    const currentDomain = (kind.tag === 37) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((kind.tag === 1) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((kind.tag === 38) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((kind.tag === 2) ? ["dataMin - 1.5", "dataMax + 1.5"] : ["dataMin", "dataMax"])));
    const values_1 = dataFromSensorValues(data);
    let patternInput;
    const arg_1 = length(data) | 0;
    toConsole(printf("%A"))(arg_1);
    const matchValue = length(data) | 0;
    patternInput = ((matchValue === 1) ? [item(0, data).Name, ".", ".", "."] : ((matchValue === 2) ? [item(0, data).Name, item(1, data).Name, ".", "."] : ((matchValue === 3) ? [item(0, data).Name, item(1, data).Name, item(2, data).Name, "."] : [item(0, data).Name, item(1, data).Name, item(2, data).Name, item(3, data).Name])));
    const makeLine = (color, key, sw, name) => {
        if (name === ".") {
            return react.createElement(react.Fragment, {});
        }
        else {
            const props_2 = {
                type: "linear",
                dataKey: key,
                dot: false,
                stroke: color,
                strokeWidth: sw,
                name: name,
            };
            return react.createElement(Line, props_2);
        }
    };
    const children_13 = ofArray([makeLine("black", "v1", 1, patternInput[0]), makeLine("blue", "v2", 1, patternInput[1]), makeLine("green", "v3", 1, patternInput[2]), makeLine("orange", "v4", 1, patternInput[3]), (props_4 = {
        stroke: "rgba(255,255,255,0.1)",
        strokeWidth: 1,
    }, react.createElement(CartesianGrid, props_4)), (props_6 = {
        dataKey: "date",
        strokeWidth: 0,
        stroke: "black",
    }, react.createElement(XAxis, props_6)), (props_8 = {
        tickFormatter: (tick) => {
            const unit = (kind.tag === 37) ? "°C" : ((kind.tag === 1) ? "°C" : ((kind.tag === 38) ? "°C" : ((kind.tag === 2) ? "°C" : "")));
            return toText(printf("%.2f %s"))(tick)(unit);
        },
        tickLine: true,
        strokeWidth: 0.1,
        stroke: "black",
        domain: ["dataMin - 1.5", "dataMax + 1.5"],
        domain: currentDomain,
        allowDecimals: false,
    }, react.createElement(YAxis, props_8)), (props_10 = {}, react.createElement(Tooltip, props_10)), (props_12 = {
        verticalAlign: "top",
    }, react.createElement(Legend, props_12))]);
    const props_14 = {
        width: 300,
        height: 300,
        data: values_1,
    };
    return react.createElement(LineChart, props_14, ...children_13);
}

