import { toString, Record, Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { Auth_ForgetPasswordResponse_$reflection } from "../Shared.fs.js";
import { Secure_rollPassword, Secure_updatePassword, FetchError_$reflection } from "../Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.24.0/Result.js";
import { record_type, option_type, tuple_type, bool_type, list_type, string_type, class_type, union_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { UI_buttonBar, UI_createField, FormChecker_isOk, FormChecker_registerChecks, FormChecker_validateField, FormChecker_resetFields, FieldValidator_Check, FieldValidator_Required, FieldValidator_Check_$reflection, FieldValidator_Required_$reflection } from "../Utils.fs.js";
import { FSharpMap__get_Item, ofSeq } from "../fable_modules/fable-library-js.4.24.0/Map.js";
import { singleton, map, ofArray, empty } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { Cmd_none, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { printf, toConsole } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Modifier_IModifier, TextAlignment_Option, Screen, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Inside_Heading_h6, Inside_Form_form, Inside_Section, Inside_Str, Inside_Notification, Inside_Span, Inside_Icon } from "../GogoFulma.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_2, h4, h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option as Option_1, notification } from "../fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { right, item, left, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { body, hero } from "../fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_3, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdatePassword", "OnUpdatePassword", "OnError", "RollPassword", "OnRollPassword", "CheckField", "EnableAction", "MailSent", "UnknownEmail", "PassTooShort", "UnknownError", "Back"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.UpdatePassword.Types.Msg", [], Types_Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_ForgetPasswordResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_ForgetPasswordResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", class_type("System.Exception")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item1", string_type], ["Item2", string_type]], [], [], [], [], [], []]);
}

export class Types_Model extends Record {
    constructor(Fields, CanDoIt, FormError, ShowInstructions, Code) {
        super();
        this.Fields = Fields;
        this.CanDoIt = CanDoIt;
        this.FormError = FormError;
        this.ShowInstructions = ShowInstructions;
        this.Code = Code;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.UpdatePassword.Types.Model", [], Types_Model, () => [["Fields", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, tuple_type(FieldValidator_Required_$reflection(), string_type, list_type(string_type), bool_type, list_type(FieldValidator_Check_$reflection()))])], ["CanDoIt", bool_type], ["FormError", option_type(string_type)], ["ShowInstructions", bool_type], ["Code", option_type(string_type)]]);
}

export const Types_initialModel = new Types_Model(ofSeq([["word1", [new FieldValidator_Required(0, []), "", empty(), false, ofArray([new FieldValidator_Check(0, []), new FieldValidator_Check(1, [3])])]], ["word2", [new FieldValidator_Required(0, []), "", empty(), false, ofArray([new FieldValidator_Check(0, []), new FieldValidator_Check(1, [3])])]], ["word3", [new FieldValidator_Required(0, []), "", empty(), false, ofArray([new FieldValidator_Check(0, []), new FieldValidator_Check(1, [3])])]]], {
    Compare: comparePrimitives,
}), false, undefined, false, undefined);

export function Commands_getResult(handler, response) {
    return handler(response);
}

export function Commands_getError(handler, response) {
    return handler(response);
}

export function Commands_UpdatePassword(code) {
    return Cmd_OfPromise_either(Secure_updatePassword, code, (response) => Commands_getResult((Item) => (new Types_Msg(1, [Item])), response), (response_1) => Commands_getError((Item_1) => (new Types_Msg(2, [Item_1])), response_1));
}

export function Commands_RollPassword(w1, w2, w3) {
    return Cmd_OfPromise_either((tupledArg) => Secure_rollPassword(tupledArg[0], tupledArg[1], tupledArg[2]), [w1, w2, w3], (response) => Commands_getResult((Item) => (new Types_Msg(4, [Item])), response), (response_1) => Commands_getError((Item_1) => (new Types_Msg(2, [Item_1])), response_1));
}

export function Update_fields(updated, model) {
    return new Types_Model(updated, model.CanDoIt, model.FormError, model.ShowInstructions, model.Code);
}

export function Update_error(error, model) {
    return new Types_Model(model.Fields, model.CanDoIt, error, model.ShowInstructions, model.Code);
}

export function Update_resetError(model) {
    return new Types_Model(model.Fields, model.CanDoIt, undefined, model.ShowInstructions, model.Code);
}

export function Update_showInstructions(model) {
    return new Types_Model(model.Fields, model.CanDoIt, model.FormError, true, model.Code);
}

export function Update_canDoAction(model) {
    return new Types_Model(model.Fields, true, model.FormError, model.ShowInstructions, model.Code);
}

export function Update_resetFields(model) {
    return Update_fields(FormChecker_resetFields(model.Fields, map((value) => value, ofArray(["word1", "word2", "word3"]))), model);
}

export function State_update(msg, model) {
    const getData = (field) => {
        const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
        return [patternInput[1], patternInput[2]];
    };
    switch (msg.tag) {
        case 5: {
            const whatField = msg.fields[1];
            return [Update_resetError(Update_fields(FormChecker_validateField(whatField, FormChecker_registerChecks(msg.fields[0], whatField, model.Fields)), new Types_Model(model.Fields, false, model.FormError, model.ShowInstructions, undefined))), Cmd_OfFunc_result(new Types_Msg(6, []))];
        }
        case 6:
            if (FormChecker_isOk(model.Fields)) {
                return [Update_canDoAction(model), Cmd_none()];
            }
            else {
                return [model, Cmd_none()];
            }
        case 1: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new Types_Model(FormChecker_registerChecks("", "word3", FormChecker_registerChecks("", "word2", FormChecker_registerChecks("", "word1", model.Fields))), false, model.FormError, model.ShowInstructions, undefined), Cmd_OfFunc_result(new Types_Msg(9, []))];
            }
            else if (response.fields[0].EmailSent) {
                return [model, Cmd_OfFunc_result(new Types_Msg(7, []))];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(8, []))];
            }
        }
        case 2:
            return [Update_error("Can\'t sign in: Server Unavailable", model), Cmd_none()];
        case 3:
            if (model.CanDoIt) {
                return [model, Commands_RollPassword(getData("word1")[0], getData("word2")[0], getData("word3")[0])];
            }
            else {
                return [Update_error("Unexpected Error!", model), Cmd_none()];
            }
        case 4: {
            const pass = msg.fields[0];
            if (pass.tag === 1) {
                toConsole(printf("%A"))(pass.fields[0]);
                return [model, Cmd_OfFunc_result(new Types_Msg(10, []))];
            }
            else {
                return [new Types_Model(model.Fields, model.CanDoIt, model.FormError, model.ShowInstructions, pass.fields[0]), Cmd_none()];
            }
        }
        case 0: {
            const matchValue_2 = model.Code;
            if (matchValue_2 == null) {
                return [Update_error("Unexpected Error!", model), Cmd_none()];
            }
            else {
                return [model, Commands_UpdatePassword(matchValue_2)];
            }
        }
        default:
            return [model, Cmd_none()];
    }
}

export function View_root(model, dispatch) {
    let s_1, s_2, children, s_3;
    const makePass = button(ofArray([new Option(0, [new Color_IColor(5, [])]), new Option(8, []), new Option(16, [!model.CanDoIt]), new Option(17, [singleton(new DOMAttr(40, [(_arg) => {
        dispatch(new Types_Msg(3, []));
    }]))])]), ofArray([Inside_Icon(new Fa_IconOption(11, ["fas fa-sign-in-alt"])), Inside_Span("Générer un mot de passe")]));
    const makePassSmall = button(ofArray([new Option(0, [new Color_IColor(5, [])]), new Option(8, []), new Option(16, [!model.CanDoIt]), new Option(17, [singleton(new DOMAttr(40, [(_arg_1) => {
        dispatch(new Types_Msg(3, []));
    }]))])]), singleton(Inside_Icon(new Fa_IconOption(11, ["fas fa-redo"]))));
    let errors;
    const matchValue = model.FormError;
    errors = ((matchValue == null) ? "" : Inside_Notification(new Color_IColor(8, []), singleton(Inside_Str(matchValue))));
    let fields;
    const mkInput = UI_createField;
    const getData = (field) => {
        const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
        return [patternInput[1], patternInput[2]];
    };
    const patternInput_1 = getData("word1");
    const patternInput_2 = getData("word2");
    const patternInput_3 = getData("word3");
    fields = Inside_Section(singleton(Inside_Form_form([Inside_Heading_h6(ofArray([(s_1 = "Veuillez saisir trois mots. À partir de ces trois mots nous allons générer un mot de passe pour vous.", s_1), react.createElement("br", {}), (s_2 = "Une fois satisfait du résultat, appuyez sur le bouton Enregistrer pour utiliser ce nouveau mot de passe.", s_2), react.createElement("br", {}), react.createElement("br", {}), (children = [(s_3 = "Appuyez sur le bouton générer autant de fois que vous le souhaitez jusqu\'à obtenir le mot de passe qui vous convient.", s_3)], react.createElement("strong", {}, ...children))])), mkInput(1, "w1", new IInputType(0, []), "Mot 1", patternInput_1[0], patternInput_1[1], new Fa_IconOption(11, ["fas fa-code"]), (inputValue) => {
        dispatch(new Types_Msg(5, [inputValue, "word1"]));
    }), mkInput(2, "w2", new IInputType(0, []), "Mot 2", patternInput_2[0], patternInput_2[1], new Fa_IconOption(11, ["fas fa-code"]), (inputValue_1) => {
        dispatch(new Types_Msg(5, [inputValue_1, "word2"]));
    }), mkInput(3, "w3", new IInputType(0, []), "Mot 3", patternInput_3[0], patternInput_3[1], new Fa_IconOption(11, ["fas fa-code"]), (inputValue_2) => {
        dispatch(new Types_Msg(5, [inputValue_2, "word3"]));
    })])));
    let pass;
    const matchValue_1 = model.Code;
    if (matchValue_1 == null) {
        pass = "";
    }
    else {
        const code = matchValue_1;
        const children_2 = [h3(empty())(singleton("Votre nouveau mot de passe :")), notification(singleton(new Option_1(0, [new Color_IColor(7, [])])), singleton(level(empty(), ofArray([left(empty(), ofArray([item(empty(), singleton(makePassSmall)), item(empty(), singleton(h4(ofArray([new Option_2(10, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))]), new Option_2(9, [singleton(["style", {
            fontFamily: "Share Tech Mono",
        }])])]))(singleton(code))))])), right(empty(), singleton(button(ofArray([new Option(8, []), new Option(16, [model.Code == null]), new Option(17, [ofArray([new DOMAttr(40, [(_arg_2) => {
            dispatch(new Types_Msg(0, []));
        }]), ["style", {
            backgroundColor: "#66C88E",
            cursor: "pointer",
        }]])])]), ofArray([Inside_Icon(new Fa_IconOption(11, ["fas fa-sign-in-alt"])), Inside_Span("Enregistrer ce mot de passe")]))))]))))];
        pass = react.createElement("div", {}, ...children_2);
    }
    return hero(empty(), singleton(body(empty(), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, [new Screen(0, []), new ISize(1, [])])), singleton("")), column(singleton(new Option_3(0, [new Screen(0, []), new ISize(1, [])])), ofArray([UI_buttonBar("Changement de mot de passe", (_arg_3) => {
        dispatch(new Types_Msg(11, []));
    }), box$0027(empty(), ofArray([fields, errors, content(empty(), singleton(react.createElement("p", {}, "Appuyez sur le bouton Générer pour créer un nouveau mot de passe à partir de vos mots."))), columns(empty(), singleton(column(empty(), singleton(makePass)))), pass]))])), column(singleton(new Option_3(0, [new Screen(0, []), new ISize(1, [])])), singleton(""))]))))));
}

