import { Union } from "../fable-library-js.4.24.0/Types.js";
import { union_type, class_type } from "../fable-library-js.4.24.0/Reflection.js";
import { ofArray, singleton } from "../fable-library-js.4.24.0/List.js";
import { Cmd_batch, Cmd_map } from "../Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgramModule_map } from "../Fable.Elmish.3.1.0/program.fs.js";

export class Navigable$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Change", "UserMsg"];
    }
}

export function Navigable$1_$reflection(gen0) {
    return union_type("Elmish.Navigation.Navigable`1", [gen0], Navigable$1, () => [[["Item", class_type("Browser.Types.Location")]], [["Item", gen0]]]);
}

/**
 * Modify current location
 */
export function Navigation_modifyUrl(newUrl) {
    return singleton((_arg) => {
        history.replaceState(undefined, "", newUrl);
    });
}

/**
 * Push new location into history and navigate there
 */
export function Navigation_newUrl(newUrl) {
    return singleton((_arg) => {
        history.pushState(undefined, "", newUrl);
        const ev = new CustomEvent("NavigatedEvent");
        window.dispatchEvent(ev);
    });
}

/**
 * Jump to some point in history (positve=forward, nagative=backward)
 */
export function Navigation_jump(n) {
    return singleton((_arg) => {
        history.go(n);
    });
}

let ProgramModule_Internal_onChangeRef = (_arg) => {
    throw new Error("`onChangeRef` has not been initialized.\nPlease make sure you used Elmish.Navigation.Program.Internal.subscribe");
};

export function ProgramModule_Internal_subscribe(dispatch) {
    let clo, clo_1, clo_2;
    let lastLocation = undefined;
    ProgramModule_Internal_onChangeRef = ((_arg) => {
        let value;
        let matchResult, href_1;
        if (lastLocation != null) {
            if (lastLocation === window.location.href) {
                matchResult = 0;
                href_1 = lastLocation;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                value = undefined;
                break;
            }
            default: {
                lastLocation = window.location.href;
                value = dispatch(new Navigable$1(0, [window.location]));
            }
        }
        return undefined;
    });
    window.addEventListener("popstate", (clo = ProgramModule_Internal_onChangeRef, (arg) => {
        clo(arg);
    }));
    window.addEventListener("hashchange", (clo_1 = ProgramModule_Internal_onChangeRef, (arg_1) => {
        clo_1(arg_1);
    }));
    window.addEventListener("NavigatedEvent", (clo_2 = ProgramModule_Internal_onChangeRef, (arg_2) => {
        clo_2(arg_2);
    }));
}

export function ProgramModule_Internal_unsubscribe() {
    let clo, clo_1, clo_2;
    window.removeEventListener("popstate", (clo = ProgramModule_Internal_onChangeRef, (arg) => {
        clo(arg);
    }));
    window.removeEventListener("hashchange", (clo_1 = ProgramModule_Internal_onChangeRef, (arg_1) => {
        clo_1(arg_1);
    }));
    window.removeEventListener("NavigatedEvent", (clo_2 = ProgramModule_Internal_onChangeRef, (arg_2) => {
        clo_2(arg_2);
    }));
}

export function ProgramModule_Internal_toNavigableWith(parser, urlUpdate, program, onLocationChange) {
    const map = (tupledArg) => [tupledArg[0], Cmd_map((Item) => (new Navigable$1(1, [Item])), tupledArg[1])];
    return ProgramModule_map((userInit, unitVar) => map(userInit(parser(window.location))), (userUpdate, msg, model_1) => map((msg.tag === 1) ? userUpdate(msg.fields[0])(model_1) : urlUpdate(parser(msg.fields[0]), model_1)), (userView, model_4, dispatch_1) => userView(model_4)((arg_1) => {
        dispatch_1(new Navigable$1(1, [arg_1]));
    }), (userSetState, model_3, dispatch) => {
        userSetState(model_3)((arg) => {
            dispatch(new Navigable$1(1, [arg]));
        });
    }, (userSubscribe, model_2) => Cmd_batch(ofArray([singleton(onLocationChange), Cmd_map((Item_1) => (new Navigable$1(1, [Item_1])), userSubscribe(model_2))])), program);
}

/**
 * Add the navigation to a program made with `mkProgram` or `mkSimple`.
 * urlUpdate: similar to `update` function, but receives parsed url instead of message as an input.
 */
export function ProgramModule_toNavigable(parser, urlUpdate, program) {
    return ProgramModule_Internal_toNavigableWith(parser, urlUpdate, program, (dispatch) => {
        ProgramModule_Internal_subscribe(dispatch);
    });
}

