import { printf, toText, join } from "./fable_modules/fable-library-js.4.24.0/String.js";
import { ofArray, empty, singleton, append } from "./fable_modules/fable-library-js.4.24.0/List.js";
import { Option, ISize, column } from "./fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Size_ISize, Color_IColor, Common_GenericOption, Modifier_IModifier, TextAlignment_Option, Screen } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { label as label_1 } from "./fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { Option as Option_1, columns } from "./fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { HTMLAttr, DOMAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { box$0027 } from "./fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { ancestor, child, Option as Option_2, parent } from "./fable_modules/Fulma.2.16.0/Layouts/Tile.fs.js";
import { Option as Option_3, h6, h5, h4, h3, h2, h1 } from "./fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { icon as icon_1 } from "./fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_i } from "./fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { TableOption, table } from "./fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { Option as Option_4, div } from "./fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { div as div_1 } from "./fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { right, Item_Option, item, left, level } from "./fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { Option as Option_5, button } from "./fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { dataTooltip } from "./fable_modules/Fulma.Extensions.Wikiki.Tooltip.3.0.0/Tooltip.fs.js";
import { Option as Option_6, switch$ } from "./fable_modules/Fulma.Extensions.Wikiki.Switch.2.0.2/Switch.fs.js";
import { Option as Option_7, section } from "./fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { Option as Option_8, container } from "./fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { divider, Dropdown_div, Link_Option, Link_a, Item_a, Option as Option_9, navbar, Brand_div, menu, End_div, Item_Option as Item_Option_1, Item_div } from "./fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.24.0/MapUtil.js";
import { Option as Option_10, tag as tag_1 } from "./fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import { Option as Option_11, notification } from "./fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { Option as Option_12, radio } from "./fable_modules/Fulma.Extensions.Wikiki.Checkradio.2.0.1/Checkradio.fs.js";
import { newGuid } from "./fable_modules/fable-library-js.4.24.0/Guid.js";
import { body, header, Option as Option_13, message } from "./fable_modules/Fulma.2.16.0/Components/Message.fs.js";
import { content } from "./fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { body as body_1, Option as Option_14, hero } from "./fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { content as content_1, card } from "./fable_modules/Fulma.2.16.0/Components/Card.fs.js";

export function Inside_TooltipClass(ccss, _arg) {
    switch (_arg) {
        case "bottom":
            return ccss(join("", ["tooltip", "has-tooltip-bottom"]));
        case "right":
            return ccss(join("", ["tooltip", "has-tooltip-right"]));
        case "left":
            return ccss(join("", ["tooltip", "has-tooltip-left"]));
        default:
            return ccss(join("", ["tooltip", "has-tooltip-top"]));
    }
}

export function Inside_ExistingList(list1, list2) {
    return append(list1, list2);
}

export function Inside_ReactList(element) {
    return singleton(element);
}

export function Inside_ColumnWithSize_Is1(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(5, [])])), elements);
}

export function Inside_ColumnWithSize_Is2(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(6, [])])), elements);
}

export function Inside_ColumnWithSize_Is3(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(7, [])])), elements);
}

export function Inside_ColumnWithSize_Is4(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(8, [])])), elements);
}

export function Inside_ColumnWithSize_Is5(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(9, [])])), elements);
}

export function Inside_ColumnWithSize_Is6(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(10, [])])), elements);
}

export function Inside_ColumnWithSize_Is7(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(11, [])])), elements);
}

export function Inside_ColumnWithSize_Is8(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(12, [])])), elements);
}

export function Inside_ColumnWithSize_Is9(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(13, [])])), elements);
}

export function Inside_ColumnWithSize_Is10(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(14, [])])), elements);
}

export function Inside_ColumnWithSize_Is11(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(15, [])])), elements);
}

export function Inside_ColumnWithSize_IsTwoThirds(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(3, [])])), elements);
}

export function Inside_ColumnWithSize_IsHalf(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(2, [])])), elements);
}

export function Inside_ColumnWithSize_IsOneThird(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(1, [])])), elements);
}

export function Inside_Label(elements) {
    return label_1(empty(), elements);
}

export function Inside_ColumnsWithClick(action, elements) {
    return columns(singleton(new Option_1(9, [singleton(new DOMAttr(40, [action]))])), elements);
}

export function Inside_ColumnsMobile(elements) {
    return columns(ofArray([new Option_1(4, []), new Option_1(9, [singleton(["style", {
        margin: "0px",
    }])])]), elements);
}

export function Inside_Columns(elements) {
    return columns(empty(), elements);
}

export function Inside_ColumnHalf(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(2, [])])), elements);
}

export function Inside_Column34(elements) {
    return column(singleton(new Option(0, [new Screen(0, []), new ISize(4, [])])), elements);
}

export function Inside_ColumnWithClick(action, elements) {
    return column(singleton(new Option(3, [singleton(new DOMAttr(40, [action]))])), elements);
}

export function Inside_ColumnCentered(elements) {
    return column(singleton(new Option(4, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))])), elements);
}

export function Inside_Column(elements) {
    return column(empty(), elements);
}

export function Inside_BoxWithClick(action, elements) {
    return box$0027(singleton(new Common_GenericOption(1, [singleton(new DOMAttr(40, [action]))])), elements);
}

export function Inside_Box(elements) {
    return box$0027(singleton(new Common_GenericOption(1, [singleton(["style", {
        height: "100%",
    }])])), elements);
}

export function Inside_Tile_WithBox(size, elements) {
    return parent(singleton(new Option_2(0, [size])), singleton(child(empty(), singleton(Inside_Box(elements)))));
}

export function Inside_Tile_Ancestor(elements) {
    return ancestor(empty(), elements);
}

export function Inside_Tile_ParentWithSize(size, elements) {
    return parent(singleton(new Option_2(0, [size])), singleton(child(empty(), elements)));
}

export function Inside_Tile_Parent(elements) {
    return parent(empty(), singleton(child(empty(), elements)));
}

export function Inside_Tile_Vertical(size, elements) {
    return parent(ofArray([new Option_2(0, [size]), new Option_2(6, [])]), singleton(child(empty(), elements)));
}

export function Inside_Tile_Child(size, elements) {
    return child(singleton(new Option_2(0, [size])), elements);
}

export function Inside_H1Text(text) {
    return h1(empty())(singleton(text));
}

export function Inside_H2Text(text) {
    return h2(empty())(singleton(text));
}

export function Inside_H3Text(text) {
    return h3(empty())(singleton(text));
}

export function Inside_H4Text(text) {
    return h4(empty())(singleton(text));
}

export function Inside_H5Text(text) {
    return h5(empty())(singleton(text));
}

export function Inside_H6Text(text) {
    return h6(empty())(singleton(text));
}

export function Inside_H1ColoredText(color, text) {
    return h1(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_H2ColoredText(color, text) {
    return h2(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_H3ColoredText(color, text) {
    return h3(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_H4ColoredText(color, text) {
    return h4(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_H5ColoredText(color, text) {
    return h5(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_H6ColoredText(color, text) {
    return h6(singleton(new Option_3(9, [singleton(["style", {
        color: color,
    }])])))(singleton(text));
}

export function Inside_Icon(name) {
    return icon_1(empty(), singleton(Fa_i(singleton(name), [])));
}

export function Inside_Span(text) {
    return react.createElement("span", {}, text);
}

export function Inside_Table_Standard(elements) {
    return table(empty(), elements);
}

export function Inside_Table_WithProps(props, elements) {
    return table(props, elements);
}

export function Inside_Table_Hoverable(props, elements) {
    return Inside_Table_WithProps(append(singleton(new TableOption(4, [])), props), elements);
}

export function Inside_Table_FullWidth(props, elements) {
    return Inside_Table_WithProps(append(singleton(new TableOption(2, [])), props), elements);
}

export function Inside_Table_Striped(props, elements) {
    return Inside_Table_WithProps(append(singleton(new TableOption(1, [])), props), elements);
}

export function Inside_Table_Narrow(props, elements) {
    return Inside_Table_WithProps(append(singleton(new TableOption(3, [])), props), elements);
}

export function Inside_Table_Thead(elements) {
    return react.createElement("thead", {}, ...elements);
}

export function Inside_Table_TR(elements) {
    return react.createElement("tr", {}, ...elements);
}

export function Inside_Table_Row(elements) {
    return Inside_Table_TR(elements);
}

export function Inside_Table_TH(elements) {
    return react.createElement("th", {}, ...elements);
}

export function Inside_Table_TD(elements) {
    return react.createElement("td", {}, ...elements);
}

export function Inside_Table_HeadColumn(elements) {
    return Inside_Table_TH(elements);
}

export function Inside_Table_Column(elements) {
    return Inside_Table_TD(elements);
}

export function Inside_Table_TBody(elements) {
    return react.createElement("tbody", {}, ...elements);
}

export function Inside_Table_Selected_TR(elements) {
    return react.createElement("tr", {
        className: "is-selected",
    }, ...elements);
}

export function Inside_Table_Selected_Row(elements) {
    return Inside_Table_Selected_TR(elements);
}

export function Inside_Control_Div(elements) {
    return div(empty(), elements);
}

export function Inside_Control_DivIconLeft(elements) {
    return div(singleton(new Option_4(1, [])), elements);
}

export function Inside_Control_DivIconRight(elements) {
    return div(singleton(new Option_4(0, [])), elements);
}

export function Inside_Form_form(elements) {
    return react.createElement("form", {}, ...elements);
}

export function Inside_Form_Label(elements) {
    return label_1(empty(), elements);
}

export function Inside_Field_Div(elements) {
    return div_1(empty(), elements);
}

export function Inside_Level_Parent(elements) {
    return level(empty(), elements);
}

export function Inside_Level_Left(elements) {
    return left(empty(), elements);
}

export function Inside_Level_Item(elements) {
    return item(empty(), elements);
}

export function Inside_Level_ItemCentered(elements) {
    return item(singleton(new Item_Option(1, [])), elements);
}

export function Inside_Level_Right(elements) {
    return right(empty(), elements);
}

export function Inside_Heading_h1(elements) {
    return h1(empty())(elements);
}

export function Inside_Heading_h2(elements) {
    return h2(empty())(elements);
}

export function Inside_Heading_h3(elements) {
    return h3(empty())(elements);
}

export function Inside_Heading_h4(elements) {
    return h4(empty())(elements);
}

export function Inside_Heading_h5(elements) {
    return h5(empty())(elements);
}

export function Inside_Heading_h6(elements) {
    return h6(empty())(elements);
}

export function Inside_Button_WithColor_Create(action, icon, title, disabled, color) {
    return button(ofArray([new Option_5(14, [disabled]), new Option_5(0, [color]), new Option_5(17, [ofArray([new DOMAttr(40, [action]), ["style", {
        cursor: "pointer",
    }]])])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_Button_WithColor_Info(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(5, []));
}

export function Inside_Button_WithColor_Warning(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(7, []));
}

export function Inside_Button_WithColor_Success(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(4, []));
}

export function Inside_Button_WithColor_Danger(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(8, []));
}

export function Inside_Button_WithColor_Light(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(2, []));
}

export function Inside_Button_WithColor_Primary(action, icon, disabled, title) {
    return Inside_Button_WithColor_Create(action, icon, title, disabled, new Color_IColor(4, []));
}

export function Inside_Button_WithColor_Custom(action, css, title) {
    return button(singleton(new Option_5(17, [ofArray([new DOMAttr(40, [action]), new HTMLAttr(64, [toText(printf("button %s"))(css)])])])), singleton(Inside_Span(title)));
}

export function Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, color) {
    return button(ofArray([new Option_5(14, [disabled]), new Option_5(0, [color]), new Option_5(17, [ofArray([new DOMAttr(40, [action]), ["style", {
        cursor: "pointer",
        minWidth: "300px",
    }]])])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_Button_WithColorAndSize_Info(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(5, []));
}

export function Inside_Button_WithColorAndSize_Warning(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(7, []));
}

export function Inside_Button_WithColorAndSize_Success(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(4, []));
}

export function Inside_Button_WithColorAndSize_Danger(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(8, []));
}

export function Inside_Button_WithColorAndSize_Light(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(2, []));
}

export function Inside_Button_WithColorAndSize_Primary(action, icon, disabled, title) {
    return Inside_Button_WithColorAndSize_Create(action, icon, title, disabled, new Color_IColor(4, []));
}

export function Inside_Button_WithColorAndSize_Custom(action, css, title) {
    return button(singleton(new Option_5(17, [ofArray([new DOMAttr(40, [action]), new HTMLAttr(64, [toText(printf("button %s"))(css)])])])), singleton(Inside_Span(title)));
}

export function Inside_Button_Small(color, action, icon, title) {
    return button(ofArray([new Option_5(0, [color]), new Option_5(1, [new Size_ISize(0, [])]), new Option_5(17, [ofArray([new DOMAttr(40, [action]), ["style", {
        cursor: "pointer",
    }]])])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_Button_SmallWithTooltip(tip, tipkind, color, action, icon, title) {
    return button(ofArray([new Option_5(0, [color]), new Option_5(1, [new Size_ISize(0, [])]), new Option_5(17, [ofArray([new DOMAttr(40, [action]), dataTooltip(tip), ["style", {
        cursor: "pointer",
    }]])]), Inside_TooltipClass((Item) => (new Option_5(19, [Item])), tipkind)]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_SwitchWithColor(color, isChecked, action, text) {
    return switch$(ofArray([new Option_6(6, [isChecked]), new Option_6(0, [color]), new Option_6(2, []), new Option_6(9, [singleton(new DOMAttr(40, [action]))])]), singleton(text));
}

export function Inside_ButtonInfoSmall(action, icon, title) {
    return button(ofArray([new Option_5(0, [new Color_IColor(5, [])]), new Option_5(1, [new Size_ISize(0, [])]), new Option_5(17, [singleton(new DOMAttr(40, [action]))])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_ButtonBlackSmall(action, icon, title) {
    return button(ofArray([new Option_5(0, [new Color_IColor(4, [])]), new Option_5(1, [new Size_ISize(0, [])]), new Option_5(17, [singleton(new DOMAttr(40, [action]))])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_ButtonSmall(action, icon, color, title) {
    return button(ofArray([new Option_5(0, [color]), new Option_5(1, [new Size_ISize(0, [])]), new Option_5(17, [singleton(new DOMAttr(40, [action]))])]), ofArray([Inside_Icon(icon), Inside_Span(title)]));
}

export function Inside_CustomSection(css, elements) {
    return section(singleton(new Option_7(0, [singleton(new HTMLAttr(64, [css]))])), elements);
}

export function Inside_SectionCentered(elements) {
    return section(singleton(new Option_7(0, [singleton(["style", {
        marginLeft: "auto",
        marginRight: "auto",
    }])])), elements);
}

export function Inside_Section(elements) {
    return section(singleton(new Option_7(0, [singleton(["style", {
        paddingRight: "0px",
        paddingLeft: "0px",
    }])])), elements);
}

export function Inside_Container_Standard(elements) {
    return container(empty(), elements);
}

export function Inside_Container_Fluid(elements) {
    return container(singleton(new Option_8(0, [])), elements);
}

export function Inside_Navbar_ItemWithClick(action, elements) {
    return Item_div(singleton(new Item_Option_1(5, [singleton(new DOMAttr(40, [action]))])), elements);
}

export function Inside_Navbar_Item(elements) {
    return Item_div(empty(), elements);
}

export function Inside_Navbar_End(elements) {
    return End_div(empty(), elements);
}

export function Inside_Navbar_Menu(elements) {
    return menu(empty(), elements);
}

export function Inside_Navbar_Brand(elements) {
    return Brand_div(empty(), elements);
}

export function Inside_Navbar_ItemAnchor(page, elements) {
    return Item_div(singleton(new Item_Option_1(5, [singleton(new HTMLAttr(94, [page]))])), elements);
}

export function Inside_Navbar_Bar(color, elements) {
    return navbar(ofArray([new Option_9(3, []), new Option_9(0, [color]), new Option_9(1, [])]), elements);
}

export function Inside_Navbar_DropdownStart(isActive, elements) {
    if (isActive) {
        return Item_a(ofArray([new Item_Option_1(3, []), new Item_Option_1(1, [true])]), elements);
    }
    else {
        return Item_a(singleton(new Item_Option_1(3, [])), elements);
    }
}

export function Inside_Navbar_link(action, elements) {
    return Link_a(singleton(new Link_Option(2, [singleton(new DOMAttr(40, [action]))])), elements);
}

export function Inside_Navbar_DropdownDiv(elements) {
    return Dropdown_div(empty(), elements);
}

export const Inside_Navbar_Divider = divider(empty(), empty());

export function Inside_Img(width, src) {
    const props = [["style", {
        width: width,
    }], new HTMLAttr(149, [src])];
    return react.createElement("img", keyValueList(props, 1));
}

export function Inside_Tag(color, text) {
    return tag_1(singleton(new Option_10(1, [color])), singleton(text));
}

export function Inside_NotificationWithClick(color, action, elements) {
    return notification(ofArray([new Option_11(0, [color]), new Option_11(3, [ofArray([new DOMAttr(40, [action]), ["style", {
        cursor: "pointer",
    }]])])]), elements);
}

export function Inside_Notification(color, elements) {
    return notification(singleton(new Option_11(0, [color])), elements);
}

export function Inside_Checkradio(color, isChecked, click, name, label) {
    let copyOfStruct;
    return radio(ofArray([new Option_12(6, [isChecked]), new Option_12(14, [name]), new Option_12(10, [singleton(new DOMAttr(40, [click]))]), new Option_12(0, [color]), new Option_12(13, [(copyOfStruct = newGuid(), copyOfStruct)])]), singleton(label));
}

export function Inside_Message_Box(color, elements) {
    return message(singleton(new Option_13(0, [color])), elements);
}

export function Inside_Message_Header(elements) {
    return header(empty(), elements);
}

export function Inside_Message_Body(elements) {
    return body(empty(), elements);
}

export function Inside_Str(text) {
    return text;
}

export function Inside_P(elements) {
    return react.createElement("p", {}, ...elements);
}

export function Inside_UL(elements) {
    return react.createElement("ul", {}, ...elements);
}

export function Inside_LI(elements) {
    return react.createElement("li", {}, ...elements);
}

export function Inside_Div(elements) {
    return react.createElement("div", {}, ...elements);
}

export function Inside_Content(elements) {
    return content(empty(), elements);
}

export function Inside_getColor(_arg) {
    switch (_arg.tag) {
        case 3:
            return "is-white";
        case 0:
            return "is-black";
        case 2:
            return "is-light";
        case 1:
            return "is-dark";
        case 4:
            return "is-primary";
        case 9:
            return "is-link";
        case 5:
            return "is-info";
        case 7:
            return "is-warning";
        case 8:
            return "is-danger";
        case 10:
            return "is-black-bis";
        case 11:
            return "is-black-ter";
        case 19:
            return _arg.fields[0];
        default:
            return "black";
    }
}

export function Inside_Hero_WithColor_FullHeight(color, elements) {
    return hero(ofArray([new Option_14(6, [color]), new Option_14(5, [])]), elements);
}

export function Inside_Hero_WithColor_Standard(elements) {
    return hero(empty(), elements);
}

export function Inside_Hero_FullHeight(elements) {
    return hero(singleton(new Option_14(5, [])), elements);
}

export function Inside_Hero_Body(elements) {
    return body_1(empty(), elements);
}

export function Inside_Hero_Standard(elements) {
    return hero(empty(), elements);
}

export function Inside_Hero_EscapeNavbar(color, elements) {
    return hero(ofArray([new Option_14(6, [color]), new Option_14(8, [singleton(new HTMLAttr(64, ["add-sticky-header hero"]))])]), elements);
}

export function Inside_Hero_FullHeightAndEscapeNavbar(color, elements) {
    return hero(ofArray([new Option_14(6, [color]), new Option_14(5, [])]), elements);
}

export function Inside_Card_Simple(elements) {
    return card(empty(), singleton(content_1(empty(), elements)));
}

