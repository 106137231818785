import { Record, Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { float64_type, record_type, list_type, tuple_type, bool_type, option_type, class_type, string_type, union_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { UserLight, DataList_$reflection, Sensor_Status_$reflection, UserLight_$reflection, DataListSequence_$reflection, Rev_$reflection } from "../Shared.fs.js";
import { Secure_Admin_saveMonitoring, Secure_probes, Secure_sensor, FetchError_$reflection } from "../Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.24.0/Result.js";
import { Types_Full_$reflection } from "../../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { FSharpMap__get_Values, FSharpMap__get_Keys, FSharpMap__get_IsEmpty, FSharpMap__Remove, FSharpMap__get_Item, FSharpMap__Add, add, empty, ofList } from "../fable_modules/fable-library-js.4.24.0/Map.js";
import { toArray, isEmpty, length as length_1, append, singleton, filter as filter_1, empty as empty_1, ofArray, map } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { mapIndexed, filter, toList, length, map as map_1 } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { unwrap, some, value as value_2 } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { substring, toConsole, printf, toText } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { Inside_Box, Inside_Heading_h5, Inside_Str, Inside_Heading_h1, Inside_Section } from "../GogoFulma.fs.js";
import * as react from "react";
import { Option, hero } from "../fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { localFormat } from "../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { toLocalTime } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Option as Option_1, tag as tag_9 } from "../fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import LineChart from "./SensorComponents/LineChart.tsx";
import { Option as Option_3, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Option as Option_2, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.24.0/MapUtil.js";
import { SimpleRefresher } from "./SensorComponents/Refresher.tsx";
import Refresher from "./SensorComponents/Refresher.tsx";
import { SimpleComponent } from "./SensorComponents/MonitoringSelector.tsx";
import MonitoringSelector from "./SensorComponents/MonitoringSelector.tsx";

export class Types_Filter extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Label"];
    }
}

export function Types_Filter_$reflection() {
    return union_type("Page.Monitoring.Types.Filter", [], Types_Filter, () => [[]]);
}

export class Types_ToggleState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Up", "Down"];
    }
}

export function Types_ToggleState_$reflection() {
    return union_type("Page.Monitoring.Types.ToggleState", [], Types_ToggleState, () => [[], []]);
}

export class Types_ToggleKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NumSerie", "Name", "Kind", "Status"];
    }
}

export function Types_ToggleKind_$reflection() {
    return union_type("Page.Monitoring.Types.ToggleKind", [], Types_ToggleKind, () => [[], [], [], []]);
}

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Load", "SaveUser", "OnSaveUser", "UnloadSensor", "LoadSensor", "OnGetSensor", "OnLoadProbes", "OnDisplayError", "RefreshData", "RefreshAllData", "ForceRefreshUser", "MonitoringError", "SensorPage"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Monitoring.Types.Msg", [], Types_Msg, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Rev_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Rev_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Types_Full_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Types_Full_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item1", Types_Full_$reflection()], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataListSequence_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", DataListSequence_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", class_type("System.Exception")]], [["Item", string_type]], [], [["Item", UserLight_$reflection()]], [["Item", string_type]], [["Item", string_type]]]);
}

export class Types_Model extends Record {
    constructor(SelectedSensor, AllSensorsMap, SelectedSensorsMap, Data, Error$, WaitingForLoading, FirstRefresh, Values, Monitoring, User) {
        super();
        this.SelectedSensor = SelectedSensor;
        this.AllSensorsMap = AllSensorsMap;
        this.SelectedSensorsMap = SelectedSensorsMap;
        this.Data = Data;
        this.Error = Error$;
        this.WaitingForLoading = WaitingForLoading;
        this.FirstRefresh = FirstRefresh;
        this.Values = Values;
        this.Monitoring = Monitoring;
        this.User = User;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Monitoring.Types.Model", [], Types_Model, () => [["SelectedSensor", option_type(string_type)], ["AllSensorsMap", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, option_type(string_type)])], ["SelectedSensorsMap", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, tuple_type(option_type(Types_Full_$reflection()), bool_type)])], ["Data", list_type(Sensor_Status_$reflection())], ["Error", option_type(string_type)], ["WaitingForLoading", bool_type], ["FirstRefresh", bool_type], ["Values", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, option_type(list_type(DataList_$reflection()))])], ["Monitoring", option_type(list_type(string_type))], ["User", UserLight_$reflection()]]);
}

export function Types_initialModel(list, user) {
    const matchValue = user.Monitoring;
    if (matchValue != null) {
        const monitoring = matchValue;
        return new Types_Model(undefined, ofList(map((s_1) => [s_1.Name, s_1.Title], list), {
            Compare: comparePrimitives,
        }), empty({
            Compare: comparePrimitives,
        }), list, undefined, false, true, empty({
            Compare: comparePrimitives,
        }), monitoring, user);
    }
    else {
        return new Types_Model(undefined, ofList(map((s) => [s.Name, s.Title], list), {
            Compare: comparePrimitives,
        }), empty({
            Compare: comparePrimitives,
        }), list, undefined, false, false, empty({
            Compare: comparePrimitives,
        }), undefined, user);
    }
}

export const State_hoursToFetch = 6;

export function State_Commands_getSensor(name, model) {
    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, model.Error, true, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_OfPromise_either(Secure_sensor, name, (response) => ((Item) => (new Types_Msg(5, [Item])))(response), (response_1) => ((Item_1) => (new Types_Msg(7, [Item_1])))(response_1))];
}

export function State_Commands_refreshSensor(name) {
    return Cmd_OfPromise_either(Secure_sensor, name, (response) => ((Item) => (new Types_Msg(5, [Item])))(response), (response_1) => ((Item_1) => (new Types_Msg(7, [Item_1])))(response_1));
}

export function State_Commands_getProbes(sensor, hours, model) {
    return [model, Cmd_OfPromise_either((tupledArg) => Secure_probes(tupledArg[0], tupledArg[1]), [sensor.Name, hours], (response) => ((tupledArg_1) => (new Types_Msg(6, [tupledArg_1[0], tupledArg_1[1]])))([sensor, response]), (response_1) => ((Item_2) => (new Types_Msg(7, [Item_2])))(response_1))];
}

export function State_Commands_refreshProbes(sensor, hours) {
    return Cmd_OfPromise_either((tupledArg) => Secure_probes(tupledArg[0], tupledArg[1]), [sensor.Name, hours], (response) => ((tupledArg_1) => (new Types_Msg(6, [tupledArg_1[0], tupledArg_1[1]])))([sensor, response]), (response_1) => ((Item_2) => (new Types_Msg(7, [Item_2])))(response_1));
}

export function State_Commands_saveUser(data_, data__1, data__2, model) {
    return [model, Cmd_OfPromise_either((tupledArg) => Secure_Admin_saveMonitoring(tupledArg[0], tupledArg[1], tupledArg[2]), [data_, data__1, data__2], (response) => ((Item) => (new Types_Msg(2, [Item])))(response), (response_1) => ((Item_1) => (new Types_Msg(7, [Item_1])))(response_1))];
}

export function State_update(msg, model) {
    let matchValue, checkV, bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 9: {
            const commands = map_1(State_Commands_refreshSensor, value_2(model.Monitoring));
            if (length(commands) > 0) {
                return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, model.Error, true, false, model.Values, model.Monitoring, model.User), Cmd_batch(commands)];
            }
            else {
                return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, model.Error, false, false, model.Values, model.Monitoring, model.User), Cmd_none()];
            }
        }
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                const e = response.fields[0];
                if (e.tag === 2) {
                    const e_1 = e.fields[0];
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, e_1, false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_OfFunc_result(new Types_Msg(11, [toText(printf("OnSaveUser %s"))(e_1)]))];
                }
                else {
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, "Unknown error", false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_OfFunc_result(new Types_Msg(11, [toText(printf("OnSaveUser e %A"))(e)]))];
                }
            }
            else {
                return [model, (matchValue = model.SelectedSensor, (matchValue != null) ? Cmd_batch(ofArray([State_Commands_refreshSensor(matchValue), Cmd_OfFunc_result(new Types_Msg(10, [model.User]))])) : Cmd_OfFunc_result(new Types_Msg(10, [model.User])))];
            }
        }
        case 6: {
            const sensor = msg.fields[0];
            const data = msg.fields[1];
            toConsole(printf("PROBES RESULT %A"))(data);
            if (data.tag === 1) {
                const e_3 = data.fields[0];
                if (e_3.tag === 2) {
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, e_3.fields[0], false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_none()];
                }
                else {
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, "Unknown error", false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_OfFunc_result(new Types_Msg(11, ["OnLoadProbes Unknown error"]))];
                }
            }
            else {
                const updated = add(sensor.Name, (checkV = ((v, s_1) => {
                    let matchResult;
                    if (v != null) {
                        if (v) {
                            matchResult = 0;
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0:
                            return some(s_1);
                        default:
                            return undefined;
                    }
                }), toList(map_1(value_2, filter((d) => (d != null), mapIndexed((i, s_2) => {
                    const matchValue_1 = (i + 1) | 0;
                    switch (matchValue_1) {
                        case 1:
                            return checkV(sensor.S1Activated, s_2);
                        case 2:
                            return checkV(sensor.S2Activated, s_2);
                        case 3:
                            return checkV(sensor.S3Activated, s_2);
                        case 4:
                            return checkV(sensor.S4Activated, s_2);
                        case 5:
                            return checkV(sensor.S5Activated, s_2);
                        case 6:
                            return checkV(sensor.S6Activated, s_2);
                        case 7:
                            return checkV(sensor.S7Activated, s_2);
                        case 8:
                            return checkV(sensor.S8Activated, s_2);
                        case 9:
                            return checkV(sensor.S9Activated, s_2);
                        case 10:
                            return checkV(sensor.S10Activated, s_2);
                        default:
                            return undefined;
                    }
                }, data.fields[0].Docs))))), model.Values);
                return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, FSharpMap__Add(model.SelectedSensorsMap, sensor.Name, [sensor, false]), model.Data, model.Error, false, model.FirstRefresh, updated, model.Monitoring, model.User), Cmd_none()];
            }
        }
        case 8: {
            const s_3 = FSharpMap__get_Item(model.SelectedSensorsMap, msg.fields[0])[0];
            const updatedSelectedMap_1 = FSharpMap__Add(model.SelectedSensorsMap, value_2(s_3).Name, [s_3, true]);
            return State_Commands_getProbes(value_2(s_3), State_hoursToFetch, new Types_Model(model.SelectedSensor, model.AllSensorsMap, updatedSelectedMap_1, model.Data, model.Error, true, model.FirstRefresh, model.Values, model.Monitoring, model.User));
        }
        case 3: {
            const s_4 = FSharpMap__get_Item(model.SelectedSensorsMap, msg.fields[0])[0];
            const updatedMap = FSharpMap__Remove(model.SelectedSensorsMap, value_2(s_4).Name);
            const all = FSharpMap__Add(model.AllSensorsMap, value_2(s_4).Name, value_2(s_4).Title);
            const updatedValues = FSharpMap__Remove(model.Values, value_2(s_4).Name);
            let patternInput_2;
            const matchValue_2 = model.User.Monitoring;
            if (matchValue_2 == null) {
                patternInput_2 = [undefined, empty_1()];
            }
            else {
                const updated_1 = filter_1((v_1) => (v_1 !== value_2(s_4).Name), matchValue_2);
                patternInput_2 = [updated_1, updated_1];
            }
            return State_Commands_saveUser(model.User.Id, model.User.Email, patternInput_2[1], new Types_Model(undefined, all, updatedMap, model.Data, model.Error, false, model.FirstRefresh, updatedValues, model.Monitoring, (bind$0040 = model.User, new UserLight(bind$0040.Id, bind$0040.Email, bind$0040.Operator, bind$0040.Address1, bind$0040.Address2, bind$0040.City, bind$0040.Zip, bind$0040.Tel, bind$0040.Country, bind$0040.Company, bind$0040.Web, bind$0040.Color, bind$0040.Logo, bind$0040.Owner, bind$0040.UserKind, bind$0040.AppOptions, bind$0040.CustomPictures, patternInput_2[0], bind$0040.ShareKey, bind$0040.Tags))));
        }
        case 5: {
            const sensor_1 = msg.fields[0];
            if (sensor_1.tag === 1) {
                const e_5 = sensor_1.fields[0];
                if (e_5.tag === 2) {
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, e_5.fields[0], false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_none()];
                }
                else {
                    return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, "Unknown error", false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_OfFunc_result(new Types_Msg(11, ["OnGetSensor Unknown error"]))];
                }
            }
            else {
                const s_5 = sensor_1.fields[0];
                return State_Commands_getProbes(s_5, State_hoursToFetch, new Types_Model(model.SelectedSensor, FSharpMap__Remove(model.AllSensorsMap, s_5.Name), FSharpMap__Add(model.SelectedSensorsMap, s_5.Name, [s_5, false]), model.Data, model.Error, model.WaitingForLoading, model.FirstRefresh, model.Values, model.Monitoring, model.User));
            }
        }
        case 7:
            return [new Types_Model(model.SelectedSensor, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, msg.fields[0].message, false, model.FirstRefresh, model.Values, model.Monitoring, model.User), Cmd_none()];
        case 4: {
            const name_3 = msg.fields[0];
            let monitoring_1;
            const matchValue_3 = model.User.Monitoring;
            monitoring_1 = ((matchValue_3 == null) ? singleton(name_3) : append(singleton(name_3), matchValue_3));
            if (length_1(monitoring_1) > 5) {
                return [model, Cmd_OfFunc_result(new Types_Msg(11, ["5 capteurs maximum sont autorisés pour le monitoring : désactivez-en un pour en activer un nouveau"]))];
            }
            else {
                return State_Commands_saveUser(model.User.Id, model.User.Email, monitoring_1, new Types_Model(name_3, model.AllSensorsMap, model.SelectedSensorsMap, model.Data, model.Error, true, model.FirstRefresh, model.Values, model.Monitoring, (bind$0040_1 = model.User, new UserLight(bind$0040_1.Id, bind$0040_1.Email, bind$0040_1.Operator, bind$0040_1.Address1, bind$0040_1.Address2, bind$0040_1.City, bind$0040_1.Zip, bind$0040_1.Tel, bind$0040_1.Country, bind$0040_1.Company, bind$0040_1.Web, bind$0040_1.Color, bind$0040_1.Logo, bind$0040_1.Owner, bind$0040_1.UserKind, bind$0040_1.AppOptions, bind$0040_1.CustomPictures, monitoring_1, bind$0040_1.ShareKey, bind$0040_1.Tags))));
            }
        }
        default:
            return [model, Cmd_none()];
    }
}

export class View_SimpleChartData extends Record {
    constructor(date, value, k) {
        super();
        this.date = date;
        this.value = value;
        this.k = k;
    }
}

export function View_SimpleChartData_$reflection() {
    return record_type("Page.Monitoring.View.SimpleChartData", [], View_SimpleChartData, () => [["date", string_type], ["value", float64_type], ["k", float64_type]]);
}

export function View_root(model, dispatch) {
    if (isEmpty(model.Data)) {
        return Inside_Section(singleton(Inside_Heading_h1(singleton(Inside_Str("Aucun capteur n\'est pour l\'instant référencé sur votre compte.")))));
    }
    else {
        const viz = FSharpMap__get_IsEmpty(model.Values) ? react.createElement(react.Fragment, {}) : hero(ofArray([new Option(1, []), new Option(6, [new Color_IColor(3, [])])]), toList(mapIndexed((index, k) => {
            let props_14, children_10, props_8, children_5, props_10, props_12;
            const values = FSharpMap__get_Item(model.Values, k);
            const patternInput = FSharpMap__get_Item(model.SelectedSensorsMap, k);
            const sensor = patternInput[0];
            const isLoading = patternInput[1];
            let title_2;
            const matchValue_1 = value_2(sensor).Title;
            title_2 = ((matchValue_1 == null) ? "" : matchValue_1);
            const graphs = (values != null) ? toList(map_1((seq_1) => {
                let props_6;
                const data = toArray(map((value) => {
                    let v_3;
                    const _arg = value.Value;
                    switch (_arg.tag) {
                        case 2: {
                            v_3 = _arg.fields[0];
                            break;
                        }
                        case 1: {
                            throw new Error("not handled");
                            break;
                        }
                        default:
                            v_3 = _arg.fields[0];
                    }
                    const d = localFormat(french, "dd/MM HH:mm", toLocalTime(value.Date));
                    return new View_SimpleChartData(toText(printf("%s"))(d), v_3, v_3 + 1);
                }, seq_1.List));
                return column(empty_1(), ofArray([tag_9(ofArray([new Option_1(1, [isLoading ? (new Color_IColor(5, [])) : (new Color_IColor(1, []))]), new Option_1(4, [singleton(["style", {
                    marginLeft: "4rem",
                }])])]), singleton(toText(printf("%s"))(seq_1.Name))), (props_6 = {
                    brushHeight: 10,
                    data: data,
                    minHeight: 200,
                }, react.createElement(LineChart, props_6))]));
            }, values)) : singleton(react.createElement(react.Fragment, {}));
            let label;
            const matchValue_2 = value_2(sensor).Title;
            if (matchValue_2 == null) {
                label = k;
            }
            else {
                const t_1 = matchValue_2;
                const max = t_1.length | 0;
                label = substring(t_1, 0, (max > 20) ? 20 : max);
            }
            return columns(empty_1(), append(singleton((props_14 = [["style", {
                transformOrigin: "0 0",
                transform: "rotate(90deg)",
                marginTop: "14px",
                width: "15px",
                height: "100%",
                cursor: "pointer",
            }], new HTMLAttr(64, ["has-text-dark has-background-dark"]), new DOMAttr(40, [(_arg_1) => {
                dispatch(new Types_Msg(12, [k]));
            }])], (children_10 = [(props_8 = [["style", {
                marginLeft: "1.5rem",
                width: 250,
                fontWeight: "700",
                textTransform: "uppercase",
            }]], (children_5 = [icon(singleton(new Option_2(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-caret-right"])), []))), toText(printf("%s     ..."))(label)], react.createElement("div", keyValueList(props_8, 1), ...children_5))), (props_10 = [["style", {
                marginLeft: "1.5rem",
            }]], react.createElement("span", keyValueList(props_10, 1), k)), (props_12 = {
                index: index,
                isLoading: isLoading,
                key: label,
                name: k,
                refresh: (name_6) => {
                    dispatch(new Types_Msg(8, [name_6]));
                },
            }, react.createElement(Refresher, props_12))], react.createElement("div", keyValueList(props_14, 1), ...children_10)))), graphs));
        }, FSharpMap__get_Keys(model.Values))));
        let tilesToSelect;
        const keys = toList(filter((x_1) => {
            const value_1 = FSharpMap__get_Item(model.AllSensorsMap, x_1);
            if (value_1 == null) {
                return false;
            }
            else {
                return true;
            }
        }, FSharpMap__get_Keys(model.AllSensorsMap)));
        tilesToSelect = (isEmpty(keys) ? Inside_Section(singleton(Inside_Heading_h5(singleton(Inside_Str("Tous les capteurs ont été sélectionnés."))))) : columns(singleton(new Option_3(2, [])), toList(map((s) => {
            const props = {
                loadSensor: (name_1) => {
                    toConsole(printf("selected sensor = %s"))(name_1);
                    dispatch(new Types_Msg(4, [name_1]));
                },
                name: s[0],
                title: s[1],
            };
            return react.createElement(SimpleComponent, props);
        }, map((x_2) => [x_2, value_2(FSharpMap__get_Item(model.AllSensorsMap, x_2))], keys)))));
        let selectedTiles;
        const values_1 = toList(filter((x_3) => (x_3[0] != null), FSharpMap__get_Values(model.SelectedSensorsMap)));
        selectedTiles = (isEmpty(values_1) ? (model.WaitingForLoading ? react.createElement(react.Fragment, {}) : Inside_Section(singleton(Inside_Box(singleton(Inside_Heading_h5(singleton(Inside_Str("Cliquez sur capteur dans la liste ci dessous pour le sélectionner.")))))))) : columns(singleton(new Option_3(2, [])), toList(map((s_1) => {
            const props_2 = {
                isLoaded: true,
                loadSensor: (name_3) => {
                    toConsole(printf("selected sensor = %s"))(name_3);
                    dispatch(new Types_Msg(4, [name_3]));
                },
                locked: model.WaitingForLoading,
                name: s_1.Name,
                title: unwrap(s_1.Title),
                unloadSensor: (name_4) => {
                    toConsole(printf("UNLOAD selected sensor = %s"))(name_4);
                    dispatch(new Types_Msg(3, [name_4]));
                },
            };
            return react.createElement(MonitoringSelector, props_2);
        }, map((x_4) => value_2(x_4[0]), values_1)))));
        let data_1;
        const children_17 = [viz, react.createElement("hr", {}), react.createElement("div", {}, selectedTiles), react.createElement("hr", {}), react.createElement("div", {}, tilesToSelect)];
        data_1 = react.createElement(react.Fragment, {}, ...children_17);
        let spinner;
        if (model.WaitingForLoading) {
            const children_18 = [icon(singleton(new Option_2(0, [new Size_ISize(2, [])])), singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-spinner"]), new Fa_IconOption(12, []), new Fa_IconOption(0, [new Fa_ISize(3, [])])]), [])))];
            spinner = react.createElement("span", {
                className: "has-text-info",
            }, ...children_18);
        }
        else {
            const children_20 = [icon(singleton(new Option_2(0, [new Size_ISize(2, [])])), singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-info"]), new Fa_IconOption(0, [new Fa_ISize(3, [])])]), [])))];
            spinner = react.createElement("span", {
                className: "has-text-dark",
            }, ...children_20);
        }
        let simpleRefresher;
        if (model.FirstRefresh) {
            const props_30 = {
                refresh: () => {
                    dispatch(new Types_Msg(9, []));
                },
            };
            simpleRefresher = react.createElement(SimpleRefresher, props_30);
        }
        else {
            simpleRefresher = react.createElement(react.Fragment, {});
        }
        return react.createElement(react.Fragment, {}, spinner, simpleRefresher, data_1);
    }
}

