import { Record, Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { Route, Route_$reflection } from "../Types.fs.js";
import { record_type, bool_type, union_type, option_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toText, printf, toConsole } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { Inside_Button_Small, Inside_Navbar_Brand, Inside_ButtonSmall } from "../GogoFulma.fs.js";
import { curry2 } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { Fa_i, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { empty, ofArray, singleton } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { icon as icon_4 } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import * as react from "react";
import { Item_div, End_div, Item_a, Start_div, Menu_Option, menu as menu_1, Burger_Option, burger as burger_1, Option as Option_1, navbar } from "../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { FrontVersionNumber } from "../Shared.fs.js";

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Help", "Disconnect", "ShowChangeLog", "DoSomething", "Back", "Options", "Goto", "Status", "ToggleBurger", "ADMUsers", "ADMMonitoring", "ADMContracts", "ToggleDisplayVideoDoc", "ToggleDisplayPDFDoc"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Navbar.Types.Msg", [], Types_Msg, () => [[], [], [], [], [], [], [["Item", Route_$reflection()]], [], [], [], [], [], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]]]);
}

export class Types_Model extends Record {
    constructor(IsBurgerOpen, VideoDoc, PDFDoc) {
        super();
        this.IsBurgerOpen = IsBurgerOpen;
        this.VideoDoc = VideoDoc;
        this.PDFDoc = PDFDoc;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Navbar.Types.Model", [], Types_Model, () => [["IsBurgerOpen", bool_type], ["VideoDoc", option_type(string_type)], ["PDFDoc", option_type(string_type)]]);
}

export function State_init() {
    return new Types_Model(false, "https://youtu.be/PakNQYFA_P8?si=2H1-96O9YYPtySnF", "https://www.mginstruments.fr/sites/default/files/01-fiche_presentation_v2.pdf");
}

export function State_update(msg, model) {
    if (msg.tag === 8) {
        return [new Types_Model(!model.IsBurgerOpen, model.VideoDoc, model.PDFDoc), Cmd_none()];
    }
    else {
        return [model, Cmd_none()];
    }
}

export function View_root(model, user, lk, dispatch) {
    let children_8, children_12, toColumn, title2, matchValue, sensors, monitoring;
    toConsole(printf("%A"))(lk);
    const doSomething = (msg, _arg) => {
        dispatch(msg);
        dispatch(new Types_Msg(8, []));
    };
    const disconnect = Inside_ButtonSmall(curry2(doSomething)(new Types_Msg(1, [])), new Fa_IconOption(11, ["fas fa-times-circle"]), new Color_IColor(1, []), "Deconnecter");
    const help = button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(1, [new Size_ISize(0, [])]), new Option(4, []), new Option(17, [singleton(new DOMAttr(40, [curry2(doSomething)(new Types_Msg(12, [model.VideoDoc]))]))])]), ofArray([icon_4(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-video"])), []))), (children_8 = [react.createElement("span", {}, "Présentation")], react.createElement("i", {}, ...children_8))]));
    const pdHelp = button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(1, [new Size_ISize(0, [])]), new Option(4, []), new Option(17, [singleton(new DOMAttr(40, [curry2(doSomething)(new Types_Msg(13, [model.PDFDoc]))]))])]), ofArray([icon_4(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-file-pdf"])), []))), (children_12 = [react.createElement("span", {}, "Premiers pas")], react.createElement("i", {}, ...children_12))]));
    return navbar(ofArray([new Option_1(1, []), new Option_1(0, [new Color_IColor(1, [])])]), ofArray([Inside_Navbar_Brand(singleton(burger_1(ofArray([new Burger_Option(3, [model.IsBurgerOpen ? "is-active" : ""]), new Burger_Option(2, [singleton(new DOMAttr(40, [(_arg_1) => {
        dispatch(new Types_Msg(8, []));
    }]))])]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})])))), menu_1(singleton(new Menu_Option(0, [model.IsBurgerOpen])), singleton(Start_div(empty(), (toColumn = ((element) => Item_a(empty(), singleton(element))), (title2 = (((user == null) ? false : ((matchValue = user.UserKind, (matchValue.tag === 1) ? true : (matchValue.tag === 0)))) ? toColumn(Inside_Button_Small(new Color_IColor(8, []), curry2(doSomething)(new Types_Msg(4, [])), new Fa_IconOption(11, ["fas fa-hand-peace"]), "Vous êtes en mode ADMIN")) : ""), (sensors = toColumn(Inside_ButtonSmall(curry2(doSomething)(new Types_Msg(4, [])), new Fa_IconOption(11, ["fas fa-list-ul"]), new Color_IColor(1, []), "Mes capteurs")), (monitoring = toColumn(Inside_ButtonSmall(curry2(doSomething)(new Types_Msg(6, [new Route(5, [])])), new Fa_IconOption(11, ["fas fa-chart-line"]), new Color_IColor(1, []), "Monitoring")), ofArray([toColumn(react.createElement("img", {
        src: "logo_mg.png",
    })), sensors, monitoring, title2])))))))), End_div(empty(), ofArray([Item_div(empty(), singleton(toText(printf("IOTPOOL %s. AIDE AU SUIVI VOS CAPTEURS RADIOS IOT 🙂"))(FrontVersionNumber))), Item_div(empty(), singleton(help)), Item_div(empty(), singleton(pdHelp)), Item_div(empty(), singleton(disconnect))]))]));
}

