import { FunctionComponent_Of_60E46241 } from "../../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { Inside_Button_WithColor_Success } from "../../GogoFulma.fs.js";
import { Fa_ISize, Fa_span, Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { h4 } from "../../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.24.0/List.js";
import { content, left, media } from "../../fable_modules/Fulma.2.16.0/Components/Media.fs.js";
import { Color_IColor, Common_GenericOption, Modifier_IModifier, Screen } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option, notification } from "../../fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { content as content_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import * as react from "react";

export const saveButton = FunctionComponent_Of_60E46241((props) => Inside_Button_WithColor_Success(props.Action, new Fa_IconOption(11, ["fas fa-save"]), props.WaitingForSave, "Enregistrer"), undefined, undefined, undefined, "saveButton", "/Users/royalefougard/workspace/iotpool-front3/src/Page/SensorComponents/ReactComponents.fs", 12);

export const customSaveButton = FunctionComponent_Of_60E46241((props) => Inside_Button_WithColor_Success(props.Action, new Fa_IconOption(11, ["fas fa-save"]), props.WaitingForSave, props.Label), undefined, undefined, undefined, "customSaveButton", "/Users/royalefougard/workspace/iotpool-front3/src/Page/SensorComponents/ReactComponents.fs", 21);

export const mediaContents = FunctionComponent_Of_60E46241((props) => {
    const title = h4(empty())(singleton(props.Title));
    return media(empty(), ofArray([left(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(24, [new Screen(3, []), true]))])), singleton(Fa_span(ofArray([props.Icon, new Fa_IconOption(0, [new Fa_ISize(4, [])])]), []))), content(empty(), singleton(notification(singleton(new Option(0, [props.Color])), ofArray([title, notification(ofArray([new Option(4, [singleton(new Modifier_IModifier(24, [new Screen(3, []), true]))]), new Option(0, [new Color_IColor(1, [])])]), singleton(content_1(empty(), props.Description))), react.createElement("div", {
        className: "block",
    }, ...props.Contents)]))))]));
}, undefined, undefined, undefined, "mediaContents", "/Users/royalefougard/workspace/iotpool-front3/src/Page/SensorComponents/ReactComponents.fs", 30);

