import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.24.0/Types.js";
import { Auth_ForgetPasswordResponse_$reflection } from "../../Shared.fs.js";
import { Auth_forgetPassword, FetchError_$reflection } from "../../Fetch.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.24.0/Result.js";
import { record_type, option_type, tuple_type, bool_type, list_type, string_type, class_type, union_type } from "../../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { UI_createField, FormChecker_isOk, FormChecker_registerChecks, FormChecker_validateField, FormChecker_resetFields, FieldValidator_Check, FieldValidator_Required, FieldValidator_Check_$reflection, FieldValidator_Required_$reflection } from "../../Utils.fs.js";
import { FSharpMap__get_Item, ofSeq } from "../../fable_modules/fable-library-js.4.24.0/Map.js";
import { singleton, map, ofArray, empty } from "../../fable_modules/fable-library-js.4.24.0/List.js";
import { comparePrimitives } from "../../fable_modules/fable-library-js.4.24.0/Util.js";
import { Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { Option, button as button_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Screen, Modifier_IModifier, Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Inside_Form_form, Inside_Str, Inside_Notification, Inside_Span, Inside_Icon } from "../../GogoFulma.fs.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { h4, Option as Option_1, h6 } from "../../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { IInputType } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_2, ISize, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { box$0027 } from "../../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import * as react from "react";

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ForgetPassword", "OnForgetPassword", "OnError", "Back", "CheckField", "EnableAction", "MailSent", "UnknownEmail", "UnknownUser", "UnknownError"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Auth.ForgetPassword.Types.Msg", [], Types_Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_ForgetPasswordResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_ForgetPasswordResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", class_type("System.Exception")]], [], [["Item1", string_type], ["Item2", string_type]], [], [], [], [], []]);
}

export class Types_Model extends Record {
    constructor(Fields, CanDoIt, FormError, ShowInstructions) {
        super();
        this.Fields = Fields;
        this.CanDoIt = CanDoIt;
        this.FormError = FormError;
        this.ShowInstructions = ShowInstructions;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Auth.ForgetPassword.Types.Model", [], Types_Model, () => [["Fields", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, tuple_type(FieldValidator_Required_$reflection(), string_type, list_type(string_type), bool_type, list_type(FieldValidator_Check_$reflection()))])], ["CanDoIt", bool_type], ["FormError", option_type(string_type)], ["ShowInstructions", bool_type]]);
}

export const Types_initialModel = new Types_Model(ofSeq([["email", [new FieldValidator_Required(0, []), "", empty(), false, ofArray([new FieldValidator_Check(0, []), new FieldValidator_Check(1, [5])])]]], {
    Compare: comparePrimitives,
}), false, undefined, false);

export function Commands_getResult(handler, response) {
    return handler(response);
}

export function Commands_getError(handler, response) {
    return handler(response);
}

export function Commands_ForgetPassword(email) {
    return Cmd_OfPromise_either(Auth_forgetPassword, email, (response) => Commands_getResult((Item) => (new Types_Msg(1, [Item])), response), (response_1) => Commands_getError((Item_1) => (new Types_Msg(2, [Item_1])), response_1));
}

export function Update_fields(updated, model) {
    return new Types_Model(updated, model.CanDoIt, model.FormError, model.ShowInstructions);
}

export function Update_error(error, model) {
    return new Types_Model(model.Fields, model.CanDoIt, error, model.ShowInstructions);
}

export function Update_resetError(model) {
    return new Types_Model(model.Fields, model.CanDoIt, undefined, model.ShowInstructions);
}

export function Update_showInstructions(model) {
    return new Types_Model(model.Fields, model.CanDoIt, model.FormError, true);
}

export function Update_canDoAction(model) {
    return new Types_Model(model.Fields, true, model.FormError, model.ShowInstructions);
}

export function Update_resetFields(model) {
    return Update_fields(FormChecker_resetFields(model.Fields, map((value) => value, singleton("email"))), model);
}

export function State_update(msg, model) {
    let patternInput;
    switch (msg.tag) {
        case 4: {
            const whatField = msg.fields[1];
            return [Update_resetError(Update_fields(FormChecker_validateField(whatField, FormChecker_registerChecks(msg.fields[0], whatField, model.Fields)), model)), Cmd_OfFunc_result(new Types_Msg(5, []))];
        }
        case 5:
            if (FormChecker_isOk(model.Fields)) {
                return [Update_canDoAction(model), Cmd_none()];
            }
            else {
                return [model, Cmd_none()];
            }
        case 1: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                const err = response.fields[0];
                const updated_1 = FormChecker_registerChecks("", "email", model.Fields);
                switch (err.tag) {
                    case 1:
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions), Cmd_OfFunc_result(new Types_Msg(8, []))];
                    case 2:
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions), Cmd_OfFunc_result(new Types_Msg(8, []))];
                    default:
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions), Cmd_OfFunc_result(new Types_Msg(8, []))];
                }
            }
            else if (response.fields[0].EmailSent) {
                return [model, Cmd_OfFunc_result(new Types_Msg(6, []))];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(7, []))];
            }
        }
        case 2:
            return [Update_error("Can\'t sign in: Server Unavailable", model), Cmd_none()];
        case 0:
            if (model.CanDoIt) {
                return [model, Commands_ForgetPassword(((patternInput = FSharpMap__get_Item(model.Fields, toString("email")), [patternInput[1], patternInput[2]]))[0])];
            }
            else {
                return [Update_error("Unexpected Error!", model), Cmd_none()];
            }
        default:
            return [model, Cmd_none()];
    }
}

export function View_root(model, dispatch) {
    let s_1, children;
    const button = button_1(ofArray([new Option(8, []), new Option(0, [new Color_IColor(4, [])]), new Option(17, [ofArray([new DOMAttr(40, [(_arg) => {
        dispatch(new Types_Msg(0, []));
    }]), ["style", {
        width: "100%",
        cursor: "pointer",
    }]])])]), ofArray([Inside_Icon(new Fa_IconOption(11, ["fas fa-sign-in-alt"])), Inside_Span("Mettre à jour mon mot de passe")]));
    let errors;
    const matchValue = model.FormError;
    errors = ((matchValue == null) ? "" : Inside_Notification(new Color_IColor(8, []), singleton(Inside_Str(matchValue))));
    let fields;
    let patternInput_1;
    const patternInput = FSharpMap__get_Item(model.Fields, toString("email"));
    patternInput_1 = [patternInput[1], patternInput[2]];
    fields = Inside_Form_form([h6(singleton(new Option_1(10, [ofArray([new Modifier_IModifier(0, [new Color_IColor(1, [])]), new Modifier_IModifier(1, [new Color_IColor(2, [])])])])))(singleton((s_1 = "Pas de soucis ! Pour changer votre mot de passe, veuillez saisir votre identifiant. Vous recevrez un message avec toutes les instructions nécessaires par email.", s_1))), UI_createField(1, "current-email", new IInputType(0, []), "Identifiant", patternInput_1[0], patternInput_1[1], new Fa_IconOption(11, ["fas fa-user"]), (inputValue) => {
        dispatch(new Types_Msg(4, [inputValue, "email"]));
    })]);
    const magicLink = button_1(ofArray([new Option(0, [new Color_IColor(1, [])]), new Option(8, []), new Option(4, []), new Option(17, [ofArray([new DOMAttr(40, [(_arg_1) => {
        dispatch(new Types_Msg(3, []));
    }]), ["style", {
        cursor: "pointer",
        width: "100%",
    }]])])]), ofArray([Inside_Icon(new Fa_IconOption(11, ["fas fa-id-badge"])), Inside_Span("Retour aux options de connexion")]));
    return columns(empty(), ofArray([column(singleton(new Option_2(0, [new Screen(1, []), new ISize(1, [])])), singleton("")), column(singleton(new Option_2(0, [new Screen(1, []), new ISize(1, [])])), ofArray([box$0027(singleton(new Common_GenericOption(2, [ofArray([new Modifier_IModifier(0, [new Color_IColor(1, [])]), new Modifier_IModifier(1, [new Color_IColor(2, [])])])])), ofArray([h4(singleton(new Option_1(10, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])))(singleton("Vous avez oublié votre mot de passe ?")), fields, errors, react.createElement("br", {}), (children = [button, react.createElement("br", {}), react.createElement("br", {})], react.createElement("p", {}, ...children))])), magicLink])), column(singleton(new Option_2(0, [new Screen(1, []), new ISize(1, [])])), singleton(""))]));
}

