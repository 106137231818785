import { Union, Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { option_type, class_type, list_type, union_type, int32_type, record_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { DATA_WINDOW_HOURS, SensorValue_$reflection } from "../Shared.fs.js";
import { FetchError_$reflection } from "../Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.24.0/Result.js";
import { Route, Route_$reflection } from "../Types.fs.js";
import { Types_ProbeKind_get_toLabel, Types_ProbeKind_get_fromString, Types_ProbeKind_$reflection } from "../../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { toLocalTime, compare, subtract } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { create } from "../fable_modules/fable-library-js.4.24.0/TimeSpan.js";
import { toList, average, head, max as max_2, min as min_1, map as map_1, last as last_1 } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { empty, singleton, ofArray, map, toArray, filter } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { localFormat } from "../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { split, toConsole, printf, toText } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import * as react from "react";
import { LineChart, YAxis, Line, ResponsiveContainer, BarChart, Tooltip, ReferenceLine, XAxis, CartesianGrid, Bar, Cell } from "recharts";
import { Tab_Option, tab, Option, tabs as tabs_1 } from "../fable_modules/Fulma.2.16.0/Components/Tabs.fs.js";
import { Modifier_IModifier, Screen, Color_IColor, Size_ISize } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { item, left, Level_Option, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.24.0/MapUtil.js";
import { h4, h5, Option as Option_1, h6 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { Option as Option_2, notification } from "../fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { Option as Option_3, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_4, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Inside_Section } from "../GogoFulma.fs.js";
import { UI_buttonBar } from "../Utils.fs.js";
import { section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";

export class Types_Payload extends Record {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = value;
    }
}

export function Types_Payload_$reflection() {
    return record_type("Page.Probe.Types.Payload", [], Types_Payload, () => [["name", string_type], ["value", float64_type]]);
}

export class Types_Selector extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LastHours"];
    }
}

export function Types_Selector_$reflection() {
    return union_type("Page.Probe.Types.Selector", [], Types_Selector, () => [[["Item", int32_type]]]);
}

export class Types_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadStats", "OnLoad", "Select", "OnError", "Route"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Probe.Types.Msg", [], Types_Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SensorValue_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SensorValue_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Types_Selector_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", Route_$reflection()]]]);
}

export class Types_Model extends Record {
    constructor(ProbeName, SensorName, Data, Actual, Kind, Error$, CurrentSelector) {
        super();
        this.ProbeName = ProbeName;
        this.SensorName = SensorName;
        this.Data = Data;
        this.Actual = Actual;
        this.Kind = Kind;
        this.Error = Error$;
        this.CurrentSelector = CurrentSelector;
    }
}

export function Types_Model_$reflection() {
    return record_type("Page.Probe.Types.Model", [], Types_Model, () => [["ProbeName", string_type], ["SensorName", string_type], ["Data", list_type(SensorValue_$reflection())], ["Actual", list_type(SensorValue_$reflection())], ["Kind", Types_ProbeKind_$reflection()], ["Error", option_type(string_type)], ["CurrentSelector", Types_Selector_$reflection()]]);
}

export function Types_initialModel(list, sensor, probe, s) {
    return new Types_Model(probe, sensor, list, list, Types_ProbeKind_get_fromString()(s.toUpperCase()), undefined, new Types_Selector(DATA_WINDOW_HOURS));
}

export function State_update(msg, model) {
    if (msg.tag === 2) {
        const selector = msg.fields[0];
        const reference_1 = subtract(last_1(model.Data).Date, create(selector.fields[0], 0, 0));
        return [new Types_Model(model.ProbeName, model.SensorName, model.Data, filter((value) => (compare(reference_1, value.Date) <= 0), model.Data), model.Kind, model.Error, selector), Cmd_none()];
    }
    else {
        return [model, Cmd_none()];
    }
}

export class View_SimpleChartData extends Record {
    constructor(date, value, k) {
        super();
        this.date = date;
        this.value = value;
        this.k = k;
    }
}

export function View_SimpleChartData_$reflection() {
    return record_type("Page.Probe.View.SimpleChartData", [], View_SimpleChartData, () => [["date", string_type], ["value", float64_type], ["k", float64_type]]);
}

export class View_SimplePieData extends Record {
    constructor(value) {
        super();
        this.value = value;
    }
}

export function View_SimplePieData_$reflection() {
    return record_type("Page.Probe.View.SimplePieData", [], View_SimplePieData, () => [["value", float64_type]]);
}

export function View_root(model, dispatch) {
    let children_12, cells, props_3, props_5, props_7, props_9, props_11, props_13, children_28, props_17, props_19, props_21, props_23, props_25, props_27, props_29, props_52, children_52, s_11, arg_12, arg_13, arg_14, arg_15;
    const dateExtractor = (value) => {
        const date_1 = localFormat(french, "dd/MM/yy HH:mm", toLocalTime(value.Date));
        return toText(printf("%s"))(date_1);
    };
    const data = toArray(map((value_1) => {
        let x_1;
        let v_3;
        const _arg = value_1.Value;
        switch (_arg.tag) {
            case 2: {
                v_3 = _arg.fields[0];
                break;
            }
            case 1: {
                throw new Error("not handled");
                break;
            }
            default:
                v_3 = _arg.fields[0];
        }
        const date_3 = localFormat(french, "dd/MM HH:mm", toLocalTime(value_1.Date));
        return new View_SimpleChartData(toText(printf("%s"))(date_3), (model.Kind.tag === 19) ? ((v_3 < -135) ? ((toConsole(printf("rssi %0.2f"))(v_3), -1)) : (((x_1 = v_3, (x_1 >= -135) && (x_1 <= -120))) ? ((toConsole(printf("rssi %0.2f"))(v_3), 1.3)) : ((toConsole(printf("rssi %0.2f"))(v_3), 2.5)))) : v_3, v_3 + 1);
    }, model.Actual));
    let patternInput;
    const values = map_1((v_4) => v_4.value, data);
    patternInput = [min_1(values, {
        Compare: comparePrimitives,
    }), max_2(values, {
        Compare: comparePrimitives,
    }), head(model.Actual), last_1(model.Actual), average(values, {
        GetZero: () => 0,
        Add: (x_7, y_2) => (x_7 + y_2),
        DivideByInt: (x_6, i) => (x_6 / i),
    })];
    const last = patternInput[3];
    const first = patternInput[2];
    let currentDomain;
    const matchValue_2 = model.Kind;
    currentDomain = ((matchValue_2.tag === 9) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((matchValue_2.tag === 5) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((matchValue_2.tag === 6) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((matchValue_2.tag === 7) ? ["dataMin - 1.5", "dataMax + 1.5"] : ((matchValue_2.tag === 11) ? ["dataMin - 0.1", "dataMax + 0.1"] : ((matchValue_2.tag === 13) ? ["dataMin", "dataMax + 1.0"] : ["dataMin", "dataMax"]))))));
    let bc;
    const children_14 = singleton((children_12 = ofArray([(cells = toList(map_1((v_5) => {
        let matchValue_3;
        const props_1 = {
            fill: (matchValue_3 = v_5.value, (matchValue_3 < 0) ? "red" : ((matchValue_3 >= 2) ? "#66C88E" : "dodgerblue")),
        };
        return react.createElement(Cell, props_1);
    }, data)), (props_3 = {
        dataKey: "value",
        fill: "rgba(0,0,0,0.2)",
    }, react.createElement(Bar, props_3, ...cells))), (props_5 = {
        stroke: "rgba(0,0,0,0.1)",
        strokeDasharray: "3 3",
        strokeWidth: 1,
    }, react.createElement(CartesianGrid, props_5)), (props_7 = {
        dataKey: "date",
        strokeWidth: 0,
        stroke: "black",
    }, react.createElement(XAxis, props_7)), (props_9 = {
        y: 0,
        stroke: "red",
    }, react.createElement(ReferenceLine, props_9)), (props_11 = {}, react.createElement(Tooltip, props_11))]), (props_13 = {
        width: 300,
        height: 300,
        data: data,
    }, react.createElement(BarChart, props_13, ...children_12))));
    const props_15 = {
        width: "100%",
        height: "80%",
        minHeight: 300,
        minWidth: 250,
    };
    bc = react.createElement(ResponsiveContainer, props_15, ...children_14);
    let graph;
    const children_30 = singleton((children_28 = ofArray([(props_17 = {
        type: "linear",
        dataKey: "value",
        stroke: "black",
        strokeWidth: 1,
    }, react.createElement(Line, props_17)), (props_19 = {
        stroke: "rgba(255,255,255,0.1)",
        strokeWidth: 1,
    }, react.createElement(CartesianGrid, props_19)), (props_21 = {
        dataKey: "date",
        strokeWidth: 0,
        stroke: "black",
    }, react.createElement(XAxis, props_21)), (props_23 = {
        tickFormatter: (tick) => {
            let unit;
            const matchValue_1 = model.Kind;
            switch (matchValue_1.tag) {
                case 9:
                case 10: {
                    unit = "%";
                    break;
                }
                case 5: {
                    unit = "°C";
                    break;
                }
                case 3: {
                    unit = "ppm";
                    break;
                }
                case 4: {
                    unit = "mV";
                    break;
                }
                case 1: {
                    unit = "VAh";
                    break;
                }
                case 2: {
                    unit = "KVAh";
                    break;
                }
                case 11: {
                    unit = "V";
                    break;
                }
                case 19: {
                    unit = "dBm";
                    break;
                }
                default:
                    unit = "";
            }
            return toText(printf("%.2f %s"))(tick)(unit);
        },
        tickLine: true,
        strokeWidth: 0.1,
        stroke: "black",
        domain: currentDomain,
    }, react.createElement(YAxis, props_23)), (props_25 = {
        y: patternInput[4],
        stroke: "#66C88E",
    }, react.createElement(ReferenceLine, props_25)), (props_27 = {}, react.createElement(Tooltip, props_27))]), (props_29 = {
        width: 300,
        height: 300,
        data: data,
    }, react.createElement(LineChart, props_29, ...children_28))));
    const props_31 = {
        width: "100%",
        height: "80%",
        minHeight: 300,
        minWidth: 250,
    };
    graph = react.createElement(ResponsiveContainer, props_31, ...children_30);
    let lastValueLabel;
    const matchValue_4 = model.Kind;
    lastValueLabel = ((matchValue_4.tag === 9) ? "HUM %" : ((matchValue_4.tag === 10) ? "%" : ((matchValue_4.tag === 5) ? "CTN °C" : ((matchValue_4.tag === 6) ? "" : ((matchValue_4.tag === 7) ? "" : ((matchValue_4.tag === 0) ? "°C" : ((matchValue_4.tag === 3) ? "ppm" : ((matchValue_4.tag === 4) ? "mV" : ((matchValue_4.tag === 1) ? "VAh" : ((matchValue_4.tag === 2) ? "KVah" : ((matchValue_4.tag === 11) ? "VOLTS" : ((matchValue_4.tag === 19) ? "dBm" : ((matchValue_4.tag === 12) ? "mm" : ((matchValue_4.tag === 13) ? "x1L" : ((matchValue_4.tag === 15) ? "x10L" : ((matchValue_4.tag === 14) ? "x1L" : ((matchValue_4.tag === 16) ? "Nombre" : ((matchValue_4.tag === 8) ? "°C" : ((matchValue_4.tag === 17) ? "Pa" : ((matchValue_4.tag === 18) ? "Watt/m2" : ""))))))))))))))))))));
    const tabs = tabs_1(ofArray([new Option(1, []), new Option(0, [new Size_ISize(2, [])])]), [tab(singleton(new Tab_Option(0, [true])), singleton(react.createElement("a", {
        onClick: (e) => {
            dispatch(new Types_Msg(2, [new Types_Selector(24)]));
        },
    }, "24h"))), tab(empty(), singleton(react.createElement("a", {
        onClick: (e_1) => {
            dispatch(new Types_Msg(2, [new Types_Selector(12)]));
        },
    }, "12h"))), tab(empty(), singleton(react.createElement("a", {
        onClick: (e_2) => {
            dispatch(new Types_Msg(2, [new Types_Selector(6)]));
        },
    }, "6h"))), tab(empty(), singleton(react.createElement("a", {
        onClick: (e_3) => {
            dispatch(new Types_Msg(2, [new Types_Selector(3)]));
        },
    }, "3h"))), tab(empty(), singleton(react.createElement("a", {
        onClick: (e_4) => {
            dispatch(new Types_Msg(2, [new Types_Selector(1)]));
        },
    }, "1h")))]);
    const name = Types_ProbeKind_get_toLabel()(model.Kind);
    const probe = last_1(split(model.ProbeName, ["."], undefined, 0));
    const name_1 = toText(printf("%s (%s) : %s"))(model.SensorName)(probe)(name);
    let graph_1;
    if (model.Kind.tag === 19) {
        const legend = (color_5, label_1) => {
            let props_50;
            return level(singleton(new Level_Option(1, [])), singleton(left(empty(), ofArray([item(empty(), singleton((props_50 = [["style", {
                backgroundColor: color_5,
                width: "10px",
                height: "30px",
            }]], react.createElement("div", keyValueList(props_50, 1))))), item(empty(), singleton(h6(singleton(new Option_1(9, [singleton(["style", {
                color: color_5,
            }])])))(singleton(label_1))))]))));
        };
        graph_1 = container(empty(), ofArray([notification(singleton(new Option_2(0, [new Color_IColor(1, [])])), ofArray([h5(empty())(singleton("Légende")), columns(singleton(new Option_3(4, [])), ofArray([column(empty(), singleton(notification(singleton(new Option_2(0, [new Color_IColor(0, [])])), singleton(legend("#66C88E", "Signal très bon"))))), column(empty(), singleton(notification(singleton(new Option_2(0, [new Color_IColor(2, [])])), singleton(legend("dodgerblue", "Signal correct"))))), column(empty(), singleton(notification(singleton(new Option_2(0, [new Color_IColor(0, [])])), singleton(legend("red", "Signal faible")))))])), (props_52 = [["style", {
            color: "rgb(255,100,0)",
        }]], (children_52 = [(s_11 = "Note: en cas de signal faible répété, veuillez contacter votre fournisseur afin de régler le problème.", s_11)], react.createElement("p", keyValueList(props_52, 1), ...children_52)))])), bc]));
    }
    else {
        graph_1 = graph;
    }
    return Inside_Section(ofArray([UI_buttonBar(name_1, (_arg_1) => {
        dispatch(new Types_Msg(4, [new Route(11, [model.SensorName])]));
    }), container(empty(), singleton(columns(ofArray([new Option_3(10, [singleton(new Modifier_IModifier(24, [new Screen(3, []), true]))]), new Option_3(9, [singleton(["style", {
        marginLeft: "3%",
        marginRight: "auto",
    }])])]), ofArray([column(empty(), singleton(notification(ofArray([new Option_2(0, [new Color_IColor(1, [])]), new Option_2(3, [empty()])]), singleton(h5(singleton(new Option_1(10, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])))(singleton((arg_12 = dateExtractor(first), toText(printf("DEBUT : %s"))(arg_12)))))))), column(empty(), singleton(notification(ofArray([new Option_2(0, [new Color_IColor(1, [])]), new Option_2(3, [empty()])]), singleton(h5(singleton(new Option_1(10, [singleton(new Modifier_IModifier(1, [new Color_IColor(2, [])]))])))(singleton((arg_13 = dateExtractor(last), toText(printf("FIN : %s"))(arg_13))))))))])))), columns(ofArray([new Option_3(10, [ofArray([new Modifier_IModifier(24, [new Screen(1, []), true]), new Modifier_IModifier(24, [new Screen(2, []), true])])]), new Option_3(9, [singleton(["style", {
        marginLeft: "3%",
        marginRight: "auto",
    }])])]), singleton(column(singleton(new Option_4(0, [new Screen(0, []), new ISize(7, [])])), singleton(notification(ofArray([new Option_2(0, [new Color_IColor(0, [])]), new Option_2(3, [singleton(["style", {
        backgroundColor: "rgb(103,144,90,0.2)",
    }])])]), ofArray([h4(singleton(new Option_1(9, [empty()])))(singleton((arg_14 = dateExtractor(first), toText(printf("du %s"))(arg_14)))), h4(singleton(new Option_1(9, [empty()])))(singleton((arg_15 = dateExtractor(last), toText(printf("au %s"))(arg_15))))])))))), section(empty(), singleton(graph_1))]));
}

