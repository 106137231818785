
/**
 * Access the value from target
 * Equivalent to `(this.target :?> HTMLInputElement).value`
 */
export function Browser_Types_Event__Event_get_Value(this$) {
    return this$.target.value;
}

/**
 * Access the checked property from target
 * Equivalent to `(this.target :?> HTMLInputElement).checked`
 */
export function Browser_Types_Event__Event_get_Checked(this$) {
    return this$.target.checked;
}

