import { Union } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import * as react from "react";

export class Types_Msg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["DoSomething"];
    }
}

export function Types_Msg_$reflection() {
    return union_type("Page.Welcome.Types.Msg", [], Types_Msg, () => [[]]);
}

export const Types_initialModel = 0;

export function State_update(msg, model) {
    return [model, Cmd_none()];
}

export function View_root(model, dispatch) {
    return react.createElement("div", {}, "welcome");
}

