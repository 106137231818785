import { Union } from "../../fable-library-js.4.24.0/Types.js";
import { Common_parseOptions, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_5A13F3A3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z749EF786, Common_GenericOptions__ToReactElement_Z6A76A149, Modifier_IModifier_$reflection, Size_ISize_$reflection } from "../Common.fs.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library-js.4.24.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.24.0/MapUtil.js";
import { some } from "../../fable-library-js.4.24.0/Option.js";
import { Reflection_getCaseName } from ".././Common.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Media.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <article class="media"></article>
 */
export function media(options, children) {
    return Common_GenericOptions__ToReactElement_Z6A76A149(Common_GenericOptions_Parse_Z749EF786(options, (result, option) => {
        let matchResult, size, props, customClass, modifiers;
        switch (option.tag) {
            case 1: {
                matchResult = 2;
                props = option.fields[0];
                break;
            }
            case 2: {
                matchResult = 3;
                customClass = option.fields[0];
                break;
            }
            case 3: {
                matchResult = 4;
                modifiers = option.fields[0];
                break;
            }
            default:
                switch (option.fields[0].tag) {
                    case 0:
                    case 1: {
                        matchResult = 0;
                        break;
                    }
                    default: {
                        matchResult = 1;
                        size = option.fields[0];
                    }
                }
        }
        switch (matchResult) {
            case 0: {
                console.warn(some("`is-small` and `is-medium` are not valid sizes for the media component"));
                return result;
            }
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            case 2:
                return Common_GenericOptions__AddProps_5A13F3A3(result, props);
            case 3:
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, modifiers);
        }
    }, "media"), (props_1, children_1) => react.createElement("article", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="media-left"></div>
 */
export function left(options, children) {
    return Common_GenericOptions__ToReactElement_Z6A76A149(Common_GenericOptions_Parse_Z749EF786(options, Common_parseOptions, "media-left"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="media-right"></div>
 */
export function right(options, children) {
    return Common_GenericOptions__ToReactElement_Z6A76A149(Common_GenericOptions_Parse_Z749EF786(options, Common_parseOptions, "media-right"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="media-content"></div>
 */
export function content(options, children) {
    return Common_GenericOptions__ToReactElement_Z6A76A149(Common_GenericOptions_Parse_Z749EF786(options, Common_parseOptions, "media-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

